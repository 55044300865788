<template>
  <div>
    <div class="_BoxHeader">
      <span @click="beforeunloadFn()">7S题库</span>
    </div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <span class="_Title">列表信息</span>
      </div>
      <div class="contain-box" :style="{ height: elementHeight + 'px' }">
        <div>
          <el-table
            :data="tableList"
            :height="elementHeight - 20"
            style="width: auto"
            :header-cell-style="{
                background: '#E8EBF4',
                color: '#5C5C5C',
                borderColor: '#C0C0C0',
            }"
            border
            id="table"
          >
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="compName" label="公司" width="180"></el-table-column>
            <el-table-column prop="deptName" label="部门" width="180"></el-table-column>
            <el-table-column prop="postName" label="岗位 " width="100"></el-table-column>
            <el-table-column prop="functionName" label="职能" width="100"></el-table-column>
            <el-table-column prop="fileNo" label="文件编号" width="100"></el-table-column>
            <el-table-column prop="fileName" label="文件名称" width="120"></el-table-column>
            <el-table-column prop="question" label="检查内容" width="120">
              <template slot-scope="scope">
                <div style="text-align: left">{{ scope.row.question }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="answer" width="380" label="标准答案">
              <template slot-scope="scope">
                <div style="text-align: left">{{ scope.row.answer }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="riskPoints" label="风险点" width="80"></el-table-column>
            <el-table-column prop="riskMeasures" label="风险应对措施" width="80"></el-table-column>
            <el-table-column prop="_7SName" label="7S名称" width="80"></el-table-column>
            <!-- <el-table-column prop="version" label="版本号" width="40">
            </el-table-column>-->
            <el-table-column prop="isCurrency" label="通用" width="80">
              <template slot-scope="scope">
                <div>{{ scope.row.isCurrency ? '√' : '' }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="isFuncCurrency" label="职能通用" width="80">
              <template slot-scope="scope">
                <div>{{ scope.row.isFuncCurrency ? '√' : '' }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="isSpecial" label="专指" width="80">
              <template slot-scope="scope">
                <div>{{ scope.row.isSpecial ? '√' : '' }}</div>
              </template>
            </el-table-column>
            <el-table-column prop align="center" label="检查类别" width="680">
              <el-table-column prop label="1">
                <el-table-column prop="areaAudit" label="现场检查" width="80">
                  <template slot-scope="scope">
                    <div>{{ scope.row.areaAudit ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop label="2">
                <el-table-column prop="fileAudit" label="文件检查" width="80">
                  <template slot-scope="scope">
                    <div>{{ scope.row.fileAudit ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table-column>

            <el-table-column prop align="center" label="所在区域">
              <el-table-column prop="arrArea" label="1">
                <el-table-column label="厂区环境-生产现场" width="180">
                  <template slot-scope="scope">
                    <div>{{ scope.row.arrArea.includes('厂区环境-生产现场') ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop label="2">
                <el-table-column prop="arrArea" label="厂区环境-营业厅" width="180">
                  <template slot-scope="scope">
                    <div>{{ scope.row.arrArea.includes('厂区环境-营业厅') ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop label="3">
                <el-table-column prop label="维修区域" width="80">
                  <template slot-scope="scope">
                    <div>{{ scope.row.arrArea.includes('维修区域') ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop label="4">
                <el-table-column prop label="仓库区域" width="80">
                  <template slot-scope="scope">
                    <div>{{ scope.row.arrArea.includes('仓库区域') ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column prop label="5">
                <el-table-column prop label="实验室区域" width="80">
                  <template slot-scope="scope">
                    <div>{{ scope.row.arrArea.includes('实验室区域') ? '√' : '' }}</div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "../../api/http";

export default {
  data() {
    return {
      elementHeight: 0,
      tableList: [],
    };
  },

  mounted() {
    this.getElementHeight();
    this.getList();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 60 + 100);
      });
    },

    //获取列表
    getList() {
      let params = {
        CompId: 1,
        DeptId: 116,
        FileNo: "FS/GF-B-048",
      };
      get("/api/QuestionPoolUpt/QuestionPoolExport", params).then((res) => {
        if (res.code == 200) {
          this.tableList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
._SelectHeader {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    padding-right: 20px;
  }
}

/deep/ .el-table .cell {
  line-height: 15px !important;
}

.contain-box {
  position: absolute;
  top: 50px;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: #e8e9ee;
  width: 100%;

  > div {
    overflow: auto;
    border: 1px solid #dbdbdb;
    display: inline-block;
    padding-right: 20px;
  }
}
</style>