<template>
  <div>
    <div class="select-header">
      <div>
        <div class="_Select">
          <span>公司：</span>
          <el-select
            v-model="value1"
            clearable
            placeholder="请选择"
            @change="compyInp"
          >
            <el-option
              v-for="item in compyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span>部门：</span>
          <el-select v-model="value2" clearable placeholder="请选择">
            <el-option
              v-for="item in deptList"
              :key="item.id"
              :label="item.deptmentName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select" style="margin-left: 10px">
          <span style="text-align: end">文件编号：</span>
          <el-input placeholder="文件编号" v-model="searFileNo"> </el-input>
        </div>
        <el-button
          style="margin-left: 10px"
          icon="el-icon-search"
          @click="searchList"
        ></el-button>
      </div>
      <div>
        <div style="margin-left: 10px">
          <el-tooltip class="item" effect="dark" content="导入文件格式为xlsx" placement="top">
            <el-button
              class="_ImgBtn"
              type="primary"
              style="margin: 0"
              @click="uploadFile"
              ><img src="../../assets/images/import2.png" style="margin: 0"
            /></el-button>
          </el-tooltip>
        </div>
        <div style="margin-left: 10px">
          <el-button
            class="_ImgBtn"
            type="primary"
            style="margin: 0"
            @click="exportExcel"
            ><img src="../../assets/images/import1.png" style="margin: 0"
          /></el-button>
        </div>
        <el-button
          type="warning"
          v-if="this.tableRowIndex != null"
          icon="el-icon-edit"
          @click="operation()"
          >修改</el-button
        >
        <el-button
          type="danger"
          v-if="deleteList.length != 0"
          icon="el-icon-delete"
          @click="deleteItem"
          >批量删除</el-button
        >
      </div>
      <input
        type="file"
        @change="importFile(this)"
        id="imFile"
        ref="fileBtn"
        style="display: none"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </div>
    <div>
      <el-table
        :data="tableList"
        :height="elementHeight"
        style="width: 100%"
        border
        id="table"
        ref="multipleTable"
        @row-click="rowClick"
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="80" v-if="isShow"> </el-table-column>
        <el-table-column prop="compName" label="公司" width="180">
        </el-table-column>
        <el-table-column prop="deptName" label="部门" width="180">
        </el-table-column>
        <el-table-column prop="postName" label="岗位" width="180">
        </el-table-column>
        <el-table-column prop="functionName" label="职能" width="130">
        </el-table-column>
        <el-table-column prop="fileNo" label="文件编号" width="130">
        </el-table-column>
        <el-table-column prop="fileName" label="文件名称" width="130">
        </el-table-column>
        <!-- <el-table-column prop="question" label="问题" width="280"> -->
        <!-- </el-table-column> -->
        <!-- <el-table-column prop="answer" label="标准答案"> </el-table-column> -->
        <el-table-column prop="interpretation" label="应知应会" width="280">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.interpretation }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="question" label="问题（答案是）" width="130">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.question }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="disturbQuestion"
          label="干扰问题（否）"
          width="280"
        >
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.disturbQuestion }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="version" label="版本号"> </el-table-column> -->
        <el-table-column prop="isCurrency" label="通用">
          <template slot-scope="scope">
            <span>{{ scope.row.isCurrency == 1 ? "√" : "" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isFuncCurrency" label="职能通用">
          <template slot-scope="scope">
            <span>{{ scope.row.isFuncCurrency == 1 ? "√" : "" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isSpecial" label="专指">
          <template slot-scope="scope">
            <span>{{ scope.row.isSpecial == 1 ? "√" : "" }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Dialog
      title="修改"
      @handleClose="handleClose"
      width="42%"
      top="10vh"
      :showTowText="true"
      @handleReqData="handleReqData"
      :showDialog="showDialog"
    >
      <div class="dialog">
        <div style="width: 400px">
          <!-- <div class="_Inp">
            <span>问题：</span>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入问题"
              v-model="question"
            >
            </el-input>
          </div> -->
          <div class="_Inp">
            <span>应知应会：</span>
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入应知应会"
              v-model="interpretation"
            >
            </el-input>
          </div>
          <div class="_Inp">
            <span>问题（答案是）：</span>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入问题"
              v-model="question"
            >
            </el-input>
          </div>
          <div class="_Inp">
            <span>干扰问题（否）：</span>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入问题"
              v-model="disturbQuestion"
            >
            </el-input>
          </div>
        </div>
        <!-- <div>
          <div class="_Inp">
            <span>标准答案：</span>
            <el-input placeholder="请输入是或否" v-model="answer"> </el-input>
          </div>
          <div class="_Inp">
            <span>版本号：</span>
            <el-input placeholder="请输入版本号" v-model="version"> </el-input>
          </div>
          <div class="_Inp">
            <span>风险点：</span>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入风险点"
              v-model="riskPoints"
            >
            </el-input>
          </div>
          <div class="_Inp">
            <span>风险应对措施：</span>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入风险应对措施"
              v-model="riskMeasures"
            >
            </el-input>
          </div>
        </div> -->
        <div style="margin-left: 50px">
          <div style="display: flex; align-items: center; margin-top: 15px">
            <span>通用：</span>
            <el-checkbox
              v-model="isCurrency"
              @change="changRowChecked1"
            ></el-checkbox>
          </div>
          <div style="display: flex; align-items: center; margin-top: 15px">
            <span>职能通用：</span>
            <el-checkbox
              v-model="isFuncCurrency"
              @change="changRowChecked2"
            ></el-checkbox>
          </div>
          <div style="display: flex; align-items: center; margin-top: 15px">
            <span>专指：</span>
            <el-checkbox
              v-model="isSpecial"
              @change="changRowChecked3"
            ></el-checkbox>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "../../components/dialog/Dialog.vue";
import { get, post, put } from "../../api/http";
import { exportExcel } from "../../modules/ExportExcel";
import XLSX from "xlsx";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      tableList: [],
      elementHeight: 0,
      showDialog: false,
      tableRowIndex: null,
      deleteList: [],
      rowItem: {},
      // 查询
      compyList: this.$store.state.companyList,
      deptList: [],
      value1: null,
      value2: null,
      searFileNo: "",
      // 弹窗参数
      answer: "",
      interpretation: "",
      question: "",
      disturbQuestion: "",
      // version: '',
      // riskPoints: '',
      // riskMeasures: '',
      isCurrency: false,
      isFuncCurrency: false,
      isSpecial: false,
      isShow:true,
    };
  },
  created() {
    this.value1 = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
    this.compyInp(this.value1);
    this.value2 = JSON.parse(sessionStorage.getItem("userInfo")).upDeptmentId;
    this.getTableList();
  },
  mounted() {
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop +75);
      });
    },
    handleClose() {
      this.showDialog = false;
    },
    rowClick(row) {
      this.tableRowIndex = row.index;
      this.rowItem = row;
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    // 搜索列表
    searchList() {
      this.getTableList();
    },
    compyInp(e) {
      this.value2 = "";
      if (e) {
        this.getDeptList(e);
      }
    },
    getTableList() {
      get(
        "/api/ExamUptQuestionPool/QuestionPoolExport?CompId=" +
          this.value1 +
          "&DeptId=" +
          this.value2 +
          "&FileNo=" +
          this.searFileNo
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableList = resp.data;
        }
      });
    },
    // 获取公司部门岗位职能
    getDeptList(e) {
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        this.deptList = res.data;
      });
    },
    // 弹窗下拉框
    changRowChecked1(e) {
      this.isCurrency = e;
    },
    changRowChecked2(e) {
      this.isFuncCurrency = e;
    },
    changRowChecked3(e) {
      this.isSpecial = e;
    },
    // 导入excel
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.isShow = false;
          this.tableList = [];
          if(!this.isShow){
            this.$nextTick( async () =>{
              await exportExcel("#table", "标准格式文件.xlsx");
              this.isShow = true;
            })
          }
          this.getTableList();
        })
        .catch((action) => {
          if(action === 'cancel'){
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      var list = [];
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        var stopExecution = false;
        excelData.map((item, index) => {
          if (stopExecution) return;
          if (
            !Object.prototype.hasOwnProperty.call(item, "公司") &&
            index == 0
          ) {
            $this.$message({
              type: "warning",
              message: "公司为空",
            });
            return;
          }
          if (
            !Object.prototype.hasOwnProperty.call(item, "应知应会") &&
            index == 0
          ) {
            $this.$message({
              type: "warning",
              message: "第一列应知应会为空",
            });
            return;
          }
          var trueName = "问题（答案是）";
          var falseName = "干扰问题（否）";
          if (!Object.prototype.hasOwnProperty.call(item, "公司")) {
            item.公司 = excelData[index - 1].公司;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "部门")) {
            item.部门 = excelData[index - 1].部门;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "岗位")) {
            item.岗位 = excelData[index - 1].岗位;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "职能")) {
            item.职能 = excelData[index - 1].职能;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "文件编号")) {
            item.文件编号 = excelData[index - 1].文件编号;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "文件名称")) {
            item.文件名称 = excelData[index - 1].文件名称;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "应知应会")) {
            item.应知应会 = excelData[index - 1].应知应会;
          }
          if (!Object.prototype.hasOwnProperty.call(item, trueName)) {
            item[trueName] = excelData[index - 1][trueName];
          }
          if (!Object.prototype.hasOwnProperty.call(item, falseName)) {
            item[falseName] = excelData[index - 1][falseName];
          }
          var data = {
            CompName: item.公司,
            DeptName: item.部门,
            PostName: item.岗位,
            FunctionName: item.职能,
            FileNo: item.文件编号,
            FileName: item.文件名称,
            // Answer: item.标准答案,
            Interpretation: item.应知应会,
            Question: item[trueName],
            DisturbQuestion: item[falseName],
            // Version: item.版本号,
            // RiskPoints: item.风险点,
            // RiskMeasures: item.风险应对措施,
            IsCurrency: item.通用 ? 1 : 0,
            IsFuncCurrency: item.职能通用 ? 1 : 0,
            IsSpecial: item.专指 ? 1 : 0,
          };
          list.push(data);
        });
        console.log(list);
        $this.handleAdd(list);
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    handleAdd(list) {
      post("/api/ExamQuestionPool/BatchAdd", {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "导入成功",
            });
            this.$refs.fileBtn.value = null
            // 导入后显示
            let c = this.compyList.filter((c) => {
              return c.companyName == list[0].CompName;
            });
            get("/api/Department/GetNoTree?CompId=" + c[0].id).then((resp) => {
              this.deptList = resp.data;
              let d = resp.data.filter((d) => {
                return d.deptmentName == list[0].DeptName;
              });
              this.value1 = c[0].id;
              this.value2 = d[0].id;
              this.getTableList();
              if (this.showDialog) this.showDialog = false;
            });
          } else {
            this.tableList = [];
            this.$message.error(resp.message);
            this.$refs.fileBtn.value = null
          }
        })
        .catch(() => {
          this.tableList = [];
          this.$message.error("导入失败");
        });
    },
    // 导出
    exportExcel() {
      this.isShow = false;
      if(!this.isShow){
        this.$nextTick( async () =>{
          await exportExcel("#table", "业务测评题库.xlsx");
          this.isShow = true;
        })
      }
    },
    
    // 修改、删除操作
    operation() {
      this.showDialog = true;
      this.answer = this.rowItem.answer;
      this.interpretation = this.rowItem.interpretation;
      this.question = this.rowItem.question;
      this.disturbQuestion = this.rowItem.disturbQuestion;
      // this.version = this.rowItem.version
      // this.riskPoints = this.rowItem.riskPoints
      // this.riskMeasures = this.rowItem.riskMeasures
      this.isCurrency = this.rowItem.isCurrency == 1 ? true : false;
      this.isFuncCurrency = this.rowItem.isFuncCurrency == 1 ? true : false;
      this.isSpecial = this.rowItem.isSpecial == 1 ? true : false;
    },
    handleReqData() {
      //修改
      var data = {
        questionId: this.rowItem.questionId,
        compName: this.rowItem.compName,
        deptName: this.rowItem.deptName,
        postName: this.rowItem.postName,
        functionName: this.rowItem.functionName,
        fileNo: this.rowItem.fileNo,
        fileName: this.rowItem.fileName,
        answer: this.answer,
        interpretation: this.interpretation,
        question: this.question,
        disturbQuestion: this.disturbQuestion,
        // version: this.version,
        // riskPoints: this.riskPoints,
        // riskMeasures: this.riskMeasures,
        isCurrency: this.isCurrency ? 1 : 0,
        isFuncCurrency: this.isFuncCurrency ? 1 : 0,
        isSpecial: this.isSpecial ? 1 : 0,
      };
      put("/api/ExamUptQuestionPool", data).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.showDialog = false;
          this.tableRowIndex = null;
          this.getTableList();
        }else{
          this.$message.error(resp.message);
        }
      });
    },
    deleteItem() {
      this.$confirm("您确认要删除当前所选中的列表？").then(() => {
        post("/api/ExamQuestionPool/BatchDelete", {
          data: JSON.stringify(this.deleteList),
        })
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getTableList();
              this.tableRowIndex = null;
            } else {
              this.$message.error(resp.message);
            }
          })
          .catch(() => {
            this.$message.error("删除失败");
          });
      });
    },
    // 勾选
    handleSelectionChange(val) {
      this.deleteList = val;
    },
  },
};
</script>

<style lang="less" scoped>
.select-header {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
}
.operation {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
}
.dialog {
  display: flex;
  justify-content: center;
  ._Select {
    margin-top: 10px;
  }
  > div:nth-of-type(2) {
    margin-left: 50px;
  }
}
._Inp span {
  width: 165px !important;
  text-align: center !important;
}
</style>