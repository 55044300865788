<template>
  <div>
    <div class="Top_box">
      <div class="select-box">
        <div>
          <span class="_Select">
            <span style="width: 50px">公司：</span>
            <el-select
              v-model="Company"
              placeholder="请选择公司"
              @change="togetArea1"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
          <span class="_Select">
            <span style="width: 70px">状态：</span>
            <el-select
              v-model="status"
              clearable
              placeholder="请选择状态"
              @change="toFind"
            >
              <!-- { label: '全部', value: }, -->
              <el-option
                v-for="item in [
                  { label: '已完成', value: 2 },
                  { label: '未完成', value: 4 },
                  { label: '有问题点', value: 5 },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </span>
          <div class="_Select year">
            <span style="width: 70px">年份：</span>
            <el-date-picker
              v-model="year"
              format="yyyy"
              value-format="yyyy"
              type="year"
              placeholder="选择年"
              @change="changeYear"
            >
            </el-date-picker>
          </div>
        </div>
        <el-button type="primary" class="edit" @click="toChooseTopic"
          ><i class="el-icon-circle-plus-outline el-icon--right"></i
          >抽取题目</el-button
        >
      </div>
    </div>
    <div class="box_">
      <span>区域：</span>
      <div
        class="_item"
        v-for="(item, index) in option2"
        :key="index"
        :aria-disabled="item.isHaveTopic"
        @click="chooseArea(index, item.id, item.isHaveTopic)"
        :v-model="Area"
        :value="item.areaName"
        :class="{
          action: curindex == index,
          Noaction: item.isHaveTopic === 0,
        }"
      >
        <span v-if="item.isHaveTopic"> {{ item.areaName }}</span>
        <el-tooltip
          v-else
          class="item"
          effect="dark"
          content="该区域没有题库"
          placement="top"
        >
          <span> {{ item.areaName }}</span>
        </el-tooltip>
      </div>
    </div>
    <div class="_TableBox">
      <el-table
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        tooltip-effect="dark"
        :height="elementHeight"
        style="width: 100%"
        highlight-current-row
        @row-dblclick="change"
        @selection-change="handleSelectionChange"
        @select="selectChange"
        @select-all="selectAll"
        @row-click="handleRow"
        :cell-style="cellStyle"
        :stripe="true"
      >
        <!-- <el-table-column type="selection" width="50"> </el-table-column> -->
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column label="所在区域" prop="auditAreaName" width="120">
        </el-table-column>

        <el-table-column
          label="抽取问题数"
          prop="titleCount"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="failCount"
          label="问题点数"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="status" v-show="scope.row.status === 0"> 未抽题 </span>
            <span class="status" v-show="scope.row.status === 1"> 已抽题</span>
            <span class="status" v-show="scope.row.status === 2"> 已完成</span>
            <span class="status" v-show="scope.row.status === 3">
              整改完成</span
            >
            <span class="status" v-show="scope.row.status === 4"> 未完成</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="completeTime"
          label="完成时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.completeTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="saveUserName"
          label="创建人"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="remark" label="备注" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <div v-if="curItem == scope.row">
              <i class="el-icon-delete" @click="delItem"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      >
      </el-pagination>
    </div>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="35%">
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属公司:</span>
        <el-select
          v-model="Company1"
          placeholder="请选择"
          class="Company_choose"
          @change="togetArea"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <span style="margin: 10px">所属区域:</span>
        <el-select
          v-model="Area1"
          placeholder="请选择"
          class="Company_choose"
          @change="changeArea"
        >
          <el-option
            v-for="item in option2"
            :key="item.value"
            :disabled="!item.isHaveTopic"
            :label="item.areaName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div style="display: flex" class="text">
        <span style="margin: 10px">添加备注:</span
        ><textarea v-model="remark" style="padding: 10px"></textarea>
      </div>
      <div style="margin-bottom: 20px" class="text">
        <div style="display: flex">
          <span style="margin: 10px">抽取题目:</span>
          <span
            @click="chooseCt"
            style="margin: 10px; color: #4274c0; cursor: pointer"
            ><i class="el-icon-plus"></i> 添加范畴</span
          >
        </div>
        <div style="display: flex">
          <div class="topic">
            <div>涉及方面</div>
            <div>总题数</div>
            <div>抽题数目</div>
            <img
              src="../../assets/images/Examine_img/top.png"
              @click="isshow = !isshow"
              alt=""
            />
          </div>
        </div>
        <el-collapse-transition>
          <div>
            <div
              style="display: flex"
              v-for="(item, index) in titlelist"
              :key="index"
            >
              <div class="topic1">
                <div>{{ item.auditTypeName }}</div>
                <div class="Centernumbet">
                  <span>{{ item.titleCount }}</span>
                </div>
                <div id="number">
                  <img
                    @click="sub(item)"
                    src="../../assets/images/Examine_img/slices/del1.png"
                    alt=""
                  />
                  <input
                    type="text"
                    style="ime-mode: disabled"
                    @keyup="test(item)"
                    v-model="item.extractCount"
                  />
                  <img
                    @click="plus(item)"
                    src="../../assets/images/Examine_img/slices/plus1.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAddInsertAreaAudit"
          >确定抽题</el-button
        >
        <el-button @click="dialogVisible = false">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加范畴" :visible.sync="dialogVisibleC" width="30%">
      <div style="height: 300px; overflow: hidden; overflow-y: auto">
        <el-tree
          :data="Ctdata"
          show-checkbox
          node-key="id"
          ref="tree"
          @check="iScheck"
          highlight-current
          :props="defaultProps"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleC = false">取 消</el-button>
        <el-button type="primary" @click="gettitle">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post } from "../../api/http";

export default {
  data() {
    return {
      elementHeight: 0,
      dialogVisible: false,
      dialogVisible1: false,
      options: [],
      option2: [],
      tableData: [],
      year: new Date().getFullYear() + '',
      Company: "",
      Area: "",
      Company1: "",
      Area1: "",
      remark: "",
      isshow: false,
      number: "",
      curArea: "",
      titlelist: [],
      CurId: "",
      CurName: "",
      ischeck: 4,
      curList: "",
      curItem: [],
      start: 0,
      end: 10,
      nodeKey: [],
      listID: [],
      extractCount: 0,
      curindex: 0,
      currentPage1: 0,
      curSize: 10,
      Ctdata: [],
      allListID: [],
      allTreeList: [],
      nodeID: [],
      halfNode: [],
      cname: [],
      dialogVisibleC: false,
      defaultProps: {
        label: "auditTypeName",
        children: "auditTypelist",
      },
      status: 4,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "SiteList") {
      to.meta.ifDoFresh = true;
    }else {
      to.meta.ifDoFresh = false;
    }
    next();
  },
  activated() {
    this.getCompany();
    this.getElementHeight();
    if(this.$route.meta.ifDoFresh){
      this.Company = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
      this.togetArea1();
    }
  },
  // activated() {
  //   if (this.$route.meta.ifDoFresh) {
  //     this.getElementHeight(); 
  //     this.$route.meta.ifDoFresh = false;
  //     this.getCompany();
  //     this.getArea();
  //     this.getAllList();
  //     // this.sortdata();
  //     // this.status = "";
  //     // this.year = "";
  //     this.currentPage1 = 1;
  //     this.curSize = 10;
  //     this.option2 = [];
  //   }else{
  //     if(this.$route.query.auditAreaNo){
  //       this.Area = this.$route.query.auditAreaNo;
  //       // this.status = "";
  //       // this.year = "";
  //       get("/api/AreaAudit/GetByAreaAudit?AreaNo=" + this.Area + "&Status=" + this.status + "&year=" + this.year).then((res) => {
  //         this.tableData = res.data;
  //       });
  //     }
  //   }
  // },
  // mounted() {
  //   this.getCompany();
  //   this.getArea();
  //   this.getElementHeight();
  //   this.getAllList();
  //   if (this.$route.query.CompanyId) {
  //     this.Company = Number(this.$route.query.CompanyId);
  //     this.Company1 = Number(this.$route.query.CompanyId);
  //     this.Area1 = Number(this.$route.query.AreaId);
  //     this.togetArea1().then(() => {
  //       this.chooseArea(this.$route.query.index, this.Area1);
  //     });
  //   } else {
  //     this.sortdata();
  //   }
  // },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  // created(){
  //   this.Company = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
  //   this.togetArea1();
  // },
  methods: {
    getCompany() {
      this.options = this.$store.state.companyUserList;
    },
    async getdata() {
      await get("/api/AreaAudit/GetList").then((res) => {
        this.tableData = res.data;
      });
    },
    sortdata() {
      get("/api/AreaAudit/GetByAreaAudit?Status=" + this.ischeck).then(
        (res) => {
          this.tableData = res.data;
        }
      );
    },
    cellStyle() {
      return "cursor:pointer;";
    },
    toFind(e) {
      this.status = this.status == null ? '' : e;
      if (this.ischeck == undefined) {
        this.ischeck = 0;
      }
      if(this.status == 5){
        get("/api/AreaAudit/GetByAreaAudit?AreaNo=" + this.Area + "&Status=" + '' + "&year=" + this.year).then((res) => {
          this.tableData = res.data;
          this.tableData = this.tableData.filter(item => item.failCount > 0);
        });
      }else{
         get("/api/AreaAudit/GetByAreaAudit?AreaNo=" + this.Area + "&Status=" + this.status + "&year=" + this.year).then((res) => {
          this.tableData = res.data;
        });
      }
      
      // if (this.Area1 === "" || this.Company === "") {
      //   this.sortdata();
      //   return;
      // }
      // this.option2.forEach((i) => {
      //   if (i.id == this.Area1) {
      //     get(
      //       "/api/AreaAudit/GetByAreaAudit?AreaNo=" +
      //         i.areaNo +
      //         "&Status=" +
      //         e +
      //         "&year=" +
      //         this.year
      //     ).then((res) => {
      //       this.tableData = res.data;
      //     });
      //   }
      // });
    },
    changeYear(e){
      this.year = this.year == null ? "" : e;
      console.log(this.Area,77);
      if(this.status == 5){
        get("/api/AreaAudit/GetByAreaAudit?AreaNo=" + this.Area + "&Status=" + '' + "&year=" + this.year).then((res) => {
          this.tableData = res.data;
          this.tableData = this.tableData.filter(item => item.failCount > 0);
        });
      }else{
         get("/api/AreaAudit/GetByAreaAudit?AreaNo=" + this.Area + "&Status=" + this.status + "&year=" + this.year).then((res) => {
          this.tableData = res.data;
        });
      }

      // this.option2.forEach((i) => {
      //   if (i.id == this.Area1) {
      //     get(
      //       "/api/AreaAudit/GetByAreaAudit?AreaNo=" +
      //         i.areaNo +
      //         "&Status=" +
      //         this.status +
      //         "&year=" +
      //         this.year
      //     ).then((res) => {
      //       this.tableData = res.data;
      //     });
      //   }
      // });
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 55 + 60 + 70); //70是div底部距离底部的高度
      });
    },
    gettitle(e) {
      this.curPost = e;
      this.option2.forEach((i) => {
        if (i.id === e) {
          this.curArea = i;
        }
      });
      this.nodeID = this.nodeID.reverse();
      for (let index = 0; index < this.nodeID.length; index++) {
        if (this.nodeKey.includes(this.nodeID[index].upId)) {
          this.nodeKey = this.nodeKey.filter((item) => {
            return item !== this.nodeID[index].id;
          });
        }
      }
      this.listID = [];
      for (let index = 0; index < this.nodeKey.length; index++) {
        var item = {
          AuditTypeId: this.nodeKey[index],
        };
        this.listID.push(item);
      }
      post("/api/AreaAudit/GetTitleList?AreaId=" + this.Area1, {
        data: JSON.stringify(this.listID),
      }).then((res) => {
        if(res.code==200){
          this.titlelist = res.data;
          this.nodeID = [];
        }
        this.dialogVisibleC = false;
      });
    },
    plus(e) {
      if (e.extractCount < e.titleCount) e.extractCount++;
    },
    sub(e) {
      if (e.extractCount > 0) e.extractCount--;
    },
    test(e) {
      if (/\D/.test(e.extractCount)) e.extractCount = "";
      if (e.extractCount > e.titleCount) e.extractCount = e.titleCount;
    },

    async toAddInsertAreaAudit() {
      this.extractCount = 0;
      if (this.Company1 === "" || this.Area1 === "") {
        this.$message({
          type: "warning",
          message: "请填写完整信息",
        });
        return;
      }
      this.titlelist.forEach((e) => {
        this.extractCount += Number(e.extractCount);
      });
      for (let index = 0; index < this.titlelist.length; index++) {
        if (this.titlelist[index].extractCount !== 0) {
          this.curList = JSON.stringify(this.titlelist);
          break;
        }
      }
      if (!this.curList) return;
      this.cname = "";
      this.option2.forEach((i) => {
        if (i.id === this.Area1) {
          this.cname = i.areaName;
          this.cNO = i.areaNo;
        }
      });
      var data = {
        jsonData: {
          data: this.curList,
        },
        areaAudit: {
          auditAreaNo: this.cNO,
          auditAreaName: this.cname,
          upCompanyId: this.Company1 + "",
          remark: this.remark,
          chargeUserId: "string",
          planId: "string",
          saveUserId: this.$store.state.userInfo.id + "",
          saveUserNo: this.$store.state.userInfo.userNo,
          saveUserName: this.$store.state.userInfo.userName,
        },
      };
      await post("/api/AreaAudit/InsertAreaAudit?AreaId=" + this.Area1, data)
        .then((res) => {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.dialogVisible = false;
        })
        .then(async () => {
          await get("/api/AreaAudit/GetByAreaAudit?AreaNo=" + this.Area + "&Status=" + this.ischeck + "&year=" + this.year).then((res) => {
            this.tableData = res.data;
            this.change(this.tableData[0]);
          });
        })
        .then(() => {});
    },
    toAddInsertAreaAudit1() {},
    chooseCt() {
      this.Ctdata = [];
      this.nodeID = [];
      get("/api/AreaAudit/GetAuditTypeList?AreaId=" + this.Area1).then(
        (res) => {
          this.dialogVisibleC = true;
          if(res.code == 200){
            this.Ctdata = res.data;
          }
        }
      );
      // this.$refs.tree.setCheckedKeys(this.nodeKey);
    },
    getAllList() {
      get("/api/AuditType/GetList").then((res) => {
        res.data.forEach((i) => {
          this.allListID.push(i.id);
        });
      });
    },
    handleTreeList(item, curIdList) {
      item.forEach((k, i) => {
        if (k.auditTypelist.length > 0) {
          this.handleTreeList(k.auditTypelist, curIdList);
        }
        if (curIdList.includes(k.id)) {
          item.splice(i, 1);
        }
      });
    },
    changeArea(e) {
      this.nodeKey = [];
      this.option2.forEach((item,index) =>{
        if(item.id == e){
          this.Area = item.areaNo;
          this.curindex = index;
        }
      })
      get("/api/AreaAudit/GetByAreaAudit?AreaNo=" + this.Area + "&Status=" + this.ischeck + "&year=" + this.year).then((res) => {
        this.tableData = res.data;
      });
    },
    change(e) {
      sessionStorage.setItem("item", JSON.stringify(e));
      this.$router.push(
        "/site-list?Id=" +
          e.id +
          "&auditAreaNo=" +
          e.auditAreaNo +
          "&upCompanyId=" +
          e.upCompanyId +
          "&index=" +
          this.curindex +
          "&check=" +
          this.ischeck +
          "&AreaId=" +
          this.Area1 +
          "&extractCount=" +
          this.extractCount
      );
    },
    delItem() {
      this.$confirm(
        "此操作将永久删除该" + this.CurName + "检查区域",
        "是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteMethod("/api/AreaAudit?Id=" + this.CurId).then((res) => {
            this.toFind(this.ischeck);
            this.$message({
              type: res.code === "200" ? "success" : "info",
              message: res.message,
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    iScheck(e, v) {
      this.nodeID = v.checkedNodes;
      this.nodeKey = v.checkedKeys;
      this.halfNode = v.halfCheckedNodes;
    },
    NochooseArea() {
      this.curindex = "";
    },
    async toFinds() {
      if (this.Area1 === "" && this.Company === "") {
        await get("/api/AreaAudit/GetList").then((res) => {
          this.tableData = res.data.filter((item) => {
            return item.failCount > 0;
          });
        });
      }
      await this.option2.forEach((i) => {
        if (i.id === this.Area1) {
          get("/api/AreaAudit/GetByAreaAudit?AreaNo=" + i.areaNo).then(
            (res) => {
              this.tableData = res.data.filter((item) => {
                return item.failCount > 0;
              });
            }
          );
        }
      });
    },
    chooseArea(e, v, k) {
      if (k === 0) return;
      this.Company1 = this.Company;
      this.curindex = e;
      this.Area1 = v;
      this.ischeck = this.status?this.status:'';
      if(this.status == 5){
        this.option2.forEach((i) => {
          if (i.id == this.Area1) {
            this.Area = i.areaNo;
            get(
              "/api/AreaAudit/GetByAreaAudit?AreaNo=" +
                i.areaNo +
                "&Status=" +
                '' +
                "&year=" + this.year
            ).then((res) => {
              this.tableData = res.data;
              this.tableData = this.tableData.filter(item => item.failCount > 0);
            });
          }
        });
      }else{
        this.option2.forEach((i) => {
          if (i.id == this.Area1) {
            this.Area = i.areaNo;
            get(
              "/api/AreaAudit/GetByAreaAudit?AreaNo=" +
                i.areaNo +
                "&Status=" +
                this.ischeck +
                "&year=" + this.year
            ).then((res) => {
              this.tableData = res.data;
            });
          }
        });
      }
    },
    getArea() {},
    async togetArea1() {
      this.option2 = [];
      this.curindex = -1;
      // this.status = '';
      // this.year = '';
      await get("/api/Area/GetByCompId?CompId=" + this.Company).then((res) => {
        this.Area = "";
        this.option2 = res.data;
        this.tableData = [];
        this.option2.forEach((item, index) => {  
          if (item.isHaveTopic === 1 && this.curindex === -1) {  
            this.curindex = index;
            this.Area = item.areaNo;
            this.chooseArea(index, item.id, item.isHaveTopic);
          }  
        });  
      });
    },
    togetArea() {
      get("/api/Area/GetByCompId?CompId=" + this.Company1).then((res) => {
        this.Area1 = "";
        // this.curindex = 0;
        this.Company = this.Company1;
        this.option2 = res.data;
      });
    },
    toChooseTopic() {
      this.dialogVisible = true;
      this.titlelist = [];
      this.nodeKey = [];
    },
    handleRow(e) {
      this.curItem = e;
      this.CurId = e.id;
      this.CurName = e.auditAreaName;
    },
    toSearch() {
      get(
        "/api/AreaAudit/GetByAreaAudit?AreaName=" +
          this.Area +
          "&Status=" +
          this.ischeck
      ).then((res) => {
        this.tableData = res.data;
      });
    },
    handleSelectionChange(value) {
      this.handleSelectionList = value;
    },

    selectChange(selection, v) {
      this.curItem = v;
      this.CurId = v.id;
      this.$refs.multipleTable.setCurrentRow(v);
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage1 - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
  },
};
</script>
<style>
.el-table__body tr.current-row > td {
  background: #d4e5f9 !important;
}
</style>
<style lang="less" scoped>
.Top_box {
  background: none;
  padding-bottom: 0px;
}
.select {
  background: #e7e9ec !important;
}

::v-deep .select .el-input__inner {
  //   border-color: rgba(255, 255, 255, 0.5);
  color: #000000;
  border: 0px;
  height: 49px;
  width: 240px;
  font-size: 20px;
  text-size-adjust: 20px;
  .el-input__inner::placeholder {
    color: rgb(0, 0, 0);
    text-align: center;
  }
}
.Noaction {
  opacity: 0.3;
}
option {
  background: #ffffff;
  line-height: 20px;
  border: 0px;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box_ {
  display: flex;
  height: 60px;
  width: 100%;
  margin-left: 5px;
  overflow-x: auto;
  border-top: 1px solid rgba(192, 192, 192, 0.411);
  margin-top: 10px;
  align-items: center;
}

::-webkit-scrollbar {
  margin-top: 3px;
  height: 6px;
  width: 0 !important;
} //设置滚动条显示隐藏
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 8px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
  background-color: rgb(218, 216, 216);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgb(92, 90, 90);
  /* -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5); */
}

.action {
  background: #4985ff !important;
  color: #ffffff !important;
}
._item {
  width: 105px;
  height: 40px;
  flex-shrink: 0;
  color: #000000;
  background: #ffffff;
  border: 1px solid #e7e9ec;
  box-shadow: 0px 2px 8px 0px rgba(160, 164, 170, 0.36);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
img {
  margin-left: 10px;
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
  }
}
.el-icon--right {
  margin-right: 5px;
  margin-left: 0;
}
/deep/ .operat-btn {
  border-radius: 50px !important;
  font-size: 10px !important;
}
@import "../../assets/style/Examine_CSS.css";
</style>
