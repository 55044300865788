<template>
  <div class="div_pagecheck">
    <div class="div_but">
      <span class="btn_sa" :class="!isshow ? 'notclick' : ''"
        :style="isshow ? 'background: #368df1;' : 'background: #CACACA;'" @click="ComAudit">完成审核</span>
      <span class="btn_cj" v-show="isshow" @click="btncj">
        <i class="el-icon-document-checked"></i>&nbsp;裁剪项
      </span>
      <span class="btn_com" v-show="!isshow" @click="btncom">
        <i class="el-icon-document-checked"></i>&nbsp;裁剪完成
      </span>
      <span class="btn_cx" v-show="!isshow" @click="cxspanclose">撤销裁剪</span>
      <span class="btn_hf" v-show="!isshow" @click="RefrehDetail">恢复默认检查清单</span>
    </div>
    <div style="height: 20px"></div>
    <div class="div_listAll" v-show="isshow">
      <template>
        <div class="div_list" v-for="item in list" :key="item.id" @dblclick="DetailsClick(item)">
          <div class="div_list_head">
            <div :style="item.evaNo.substring(0, 1) == '1'
              ? 'background:#ED4507'
              : item.evaNo.substring(0, 1) == '2'
                ? 'background:#28ADB9'
                : 'background:#434343'
              ">{{ item.evaNo }}</div>
            <template v-if="item.isAlreadySeen">
              <span v-show="item.auditScore != 0" class="span_kf">-&nbsp;{{ item.auditScore }}</span>
            </template>
            <template v-else>
              <span v-show="item.auditScore != 0" class="span_kf">-&nbsp;{{ item.auditScore }}</span>
              <span v-show="item.auditScore == 0">
                <el-button type="primary" class="notclick" icon="el-icon-check" circle></el-button>
              </span>
            </template>
          </div>
          <div class="div_list_hr"></div>
          <div class="div_cont">
            <p class="p_evaItem">{{ item.evaItem }}</p>
            <p class="p_evaContent">{{ item.evaContent }}</p>
            <p class="p_appraise">
              <span class="span_standard">评价标准：</span>
              <span class="span_basis">评价依据：</span>
            </p>
            <p class="p_appraise1">
              <span class="span_standard1">{{ item.evaStandard }}</span>
              <span class="span_basis1">
                {{ item.evaBasis
                }}
              </span>
            </p>
          </div>
          <div class="div_db">
            <p class="p_kfpic">
              <span class="span_kfxq">扣分：</span>
              <span class="span_xhx1">{{ item.auditScore }}</span>
              <span class="span_pic">证据图片：</span>
              <span class="span_xhx2">{{ item.picNumber }}</span>
            </p>
            <div class="div_nohg">
              <p class>不合格描述:</p>

              <div>{{ item.auditDescribe }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="div_listAll" v-show="!isshow">
      <template>
        <div class="div_list1" v-for="item in list" :key="item.id" v-show="Cutting.filter((e) => e == item.id).length == 0 &&
          item.isApply == true
          ">
          <div class="div_list_head">
            <div :style="item.evaNo.substring(0, 1) == '1'
              ? 'background:#ED4507'
              : item.evaNo.substring(0, 1) == '2'
                ? 'background:#28ADB9'
                : 'background:#434343'
              ">{{ item.evaNo }}</div>
            <template>
              <span class="span_close" @click="spanclose(item)">
                <i class="el-icon-close"></i>
              </span>
            </template>
          </div>
          <div class="div_list_hr"></div>
          <div class="div_cont1">
            <p class="p_evaItem">{{ item.evaItem }}</p>
            <p class="p_evaContent">{{ item.evaContent }}</p>
            <p class="p_appraise">
              <span class="span_standard">评价标准：</span>
              <span class="span_basis">评价依据：</span>
            </p>
            <p class="p_appraise1">
              <span class="span_standard1">{{ item.evaStandard }}</span>
              <span class="span_basis1">
                {{ item.evaBasis
                }}
              </span>
            </p>
          </div>
        </div>
      </template>
    </div>
    <el-dialog title="检查详情" :visible.sync="dialogVisible" width="765px" :close-on-click-modal="false"
      :before-close="DetailDialogClose">
      <div class="DetailHead" :style="{ height: DetailHeadHeight }">
        <div class="DetailHeadLf">{{ DetailList.evaNo }}</div>
        <p class="p_DetailHeadt">{{ DetailList.evaItem }}</p>
        <p class="p_DetailHeadm">
          考核扣分：
          <span v-show="AuditScore != 0" class="span_Headm">&nbsp;-&nbsp;{{ AuditScore }}&nbsp;</span>
        </p>
        <div class="div_DetailHeadb">
          <p class="p_DetailHeadb">不合格描述：</p>
          <p class="span_Headb">{{ AuditDescribe }}</p>
        </div>
      </div>
      <hr class="DetailHeadHr" />
      <div class="DetailMiddle">
        <p class="p_DetailPf">项目内容</p>
        <p class="DM_evaContent">{{ DetailList.evaContent }}</p>
        <div style="float: left; width: 100%; margin-top: 10px">
          <p class="p_evaSt">考核标准：</p>
          <p class="p_evaStandard">{{ DetailList.evaStandard }}</p>
        </div>
        <div style="
            float: left;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 20px;
          ">
          <p class="p_evaSt">评价依据：</p>
          <div class="p_evaStandard">
            <template v-if="DetailList.length != 0">
              <template>
                <p v-for="(i, index) in DetailList.evaBasis.split('；')" :key="index">{{ i }}</p>
              </template>
            </template>
          </div>
        </div>
      </div>
      <hr class="DetailMiddleHr" />
      <div class="DetailBottom">
        <p class="p_DetailPf">评分情况</p>
        <p class="p_DetailFz">扣掉分值：</p>
        <p class="p_AuditScore">
          <input class="txt" :readonly="DetailList.isApply == false || IsFinish == true ? true : false
            " type="text" v-model="AuditScore" />
        </p>
        <p class="p_DetailDesc">不合格描述：</p>
        <p class="p_AuditDescribe">
          <textarea :readonly="DetailList.isApply == false || IsFinish == true ? true : false
            " class="txtarea" v-model="AuditDescribe"></textarea>
        </p>
        <div style="float: left; width: 100%; margin-top: 10px">
          <div class="div_DetailPic">证据照片：</div>
          <div class="p_pic">
            <div class="imgall">
              <template>
                <div v-for="itemPic in DetailPic" :key="itemPic.id" class="ImgList">
                  <div>
                    <img class="haveImg" :src="itemPic.picNameAndAddr" @click="imgclick(itemPic.picNameAndAddr)" />
                    <span class="span_delete" :class="IsFinish ? 'notclick' : ''" @click="delimg(itemPic.id)">删除</span>
                  </div>
                </div>
              </template>
            </div>

            <el-upload v-show="!IsFinish && DetailList.isApply" class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card"
              :on-preview="handlePictureCardPreview" :before-upload="beforeAvatarUpload" :on-remove="handleRemove"
              :http-request="UploadFile" ref="upload">
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <el-dialog :visible.sync="ImgVisible" :append-to-body="true">
              <img width="100%" :src="dialogImageUrl" alt class="avatar" />
            </el-dialog>
          </div>
        </div>
      </div>
      <hr class="DetailMiddleHr" />
    </el-dialog>
  </div>
</template>
<script>
import { post, get, deleteMethod, put,baseURL } from "../../api/http";
import $ from "jquery";
// import axios from "axios";
export default {
  data() {
    return {
      DetailHeadHeight: "95px",
      list: [],
      compId: "",
      UserNo: "",
      UserName: "",
      UserId: 0,
      SmallPlanId: 0,
      isGvo: true,
      DetailList: [],
      isshow: true,
      Cutting: [],
      isSave: true,
      dialogVisible: false,
      IsFinish: false,
      AuditScore: "",
      AuditDescribe: "",
      dialogImageUrl: "",
      ImgVisible: false,
      ImgFile: [],
      DetailPic: [],
      BUrl: baseURL[process.env.NODE_ENV + ""],
    };
  },
  created() {
    this.UserId = JSON.parse(sessionStorage.getItem("userInfo")).id;
    this.UserNo = JSON.parse(sessionStorage.getItem("userInfo")).userNo;
    this.UserName = JSON.parse(sessionStorage.getItem("userInfo")).userName;
    this.compId = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
    this.SmallPlanId = this.$route.query == undefined ? 0 : this.$route.query.SmallPlanId;
    this.isGvo = this.$route.query == undefined ? true : this.$route.query.isGvo;
    if (this.SmallPlanId != 0 && this.SmallPlanId != undefined) {
      this.GetList();
      this.GetIsFinish();
    }
    this.isshow = true;
    this.IsFinish = false;
    this.Cutting = [];
    this.isSave = true;
    this.dialogVisible = false;
    this.ImgFile = [];
  },

  methods: {
    GetList() {
      post(
        "/api/WsmPlanDetail?planid=" +
        this.SmallPlanId +
        "&userid=" +
        this.UserId +
        "&isGvo=" +
        this.isGvo,
      ).then((resp) => {
        if (resp.data.length > 0) {
          this.list = resp.data;
        }
      });
    },
    GetIsFinish() {
      get(
        "/api/WsmPlanMain/GetIsFinsh",
        { id: this.SmallPlanId },
      ).then((resp) => {
        if (resp.code == 200) {
          this.IsFinish = resp.message == "失败！"? false : true;
        }
      });
    },
    bindDetailPic(id) {
      get("/api/WsmPlanPic/" + id,).then((resp) => {
        if (resp.code == 200) {
          var arrDetailPic = [];
          resp.data.forEach((e) => {
            arrDetailPic.push({
              id: e.id,
              picNameAndAddr: this.BUrl + '/' +  e.picNameAndAddr,
            });
          });
          this.DetailPic = arrDetailPic;
        }
      });
    },
    bindDetail() {
      get("/api/WsmPlanDetail/" + this.SmallPlanId).then(
        (resp) => {
          if (resp.code == 200) {
            this.list = resp.data;
          }
        }
      );
    },
    DetailsClick(e) {
      this.dialogVisible = true;
      this.DetailList = e;
      this.AuditScore = e.auditScore == 0 ? "" : e.auditScore;
      this.AuditDescribe = e.auditDescribe;
      this.bindDetailPic(e.id);
    },
    btncj() {
      if (this.IsFinish) {
        this.$message({
          message: "此详情已审核完毕，不可再裁剪",
          type: "warning",
        });
        return;
      }
      this.isshow = false;
      this.isSave = false;
    },
    btncom: async function () {
      if (this.Cutting.length == 0) {
        this.$message({
          type: "success",
          message: "裁剪成功!",
        });
        this.bindDetail();
        this.Cutting = [];
        this.isshow = true;
        this.isSave = true;
      } else {
        for (let i = 0; i < this.Cutting.length; i++) {
          var b = true;
          await deleteMethod("/api/WsmPlanDetail/" + this.Cutting[i],)
            .then((resp) => {
              if (resp.code != 200) {
                throw new Error("裁剪失败");
              }
            })
            .then(() => {
              if (i == this.Cutting.length - 1) {
                this.$message({
                  type: "success",
                  message: "裁剪成功!",
                });
                this.bindDetail();
                this.Cutting = [];
                this.isshow = true;
                this.isSave = true;
              }
            })
            .catch((error) => {
              b = false;
              this.$message.error(error.message);
            });
          if (!b) {
            return false;
          }
        }
      }
    },
    spanclose(e) {
      this.Cutting.push(e.id);
    },
    cxspanclose() {
      if (this.Cutting.length == 0) {
        this.$message({
          message: "没有可撤销的的操作项！",
          type: "warning",
        });
        return;
      }
      this.Cutting.pop();
    },
    ComAudit() {
      if (this.IsFinish) {
        this.$message({
          message: "此详情已审核完毕，不可重复审核",
          type: "warning",
        });
        return;
      }
      var length = this.list.filter((e) => e.isAlreadySeen == 0).length;
      if (length > 0) {
        var msg = "";
        this.list
          .filter((e) => e.isAlreadySeen == 0)
          .forEach((e) => {
            msg = msg + e.evaNo + "、";
          });
        msg = msg.substring(0, msg.length - 1);
        this.$message({
          message: "以下编号{" + msg + "}还未检查！",
          type: "warning",
        });
        return;
      }
      get(
        "/api/WsmPlanDetail/Finish",
        {
          PlanId: this.SmallPlanId,
          UserName: this.UserName,
          UserNo: this.UserNo,
        },
      ).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "审核完成",
          });
          this.GetIsFinish();
        } else {
          this.$message.error("审核失败");
        }
      });
    },
    DetailDialogClose() {
      if (this.IsFinish) {
        this.dialogVisible = false;
        this.DetailList = [];
        this.ImgFile = [];
        return;
      }
      // this.DetailList.isAlreadySeen = true;
      this.DetailList.isAlreadySeen = 1;
      this.DetailList.auditScore = this.AuditScore == "" ? 0 : this.AuditScore;
      this.DetailList.auditDescribe = this.AuditDescribe;
      if (this.ImgFile.length > 0) {
        var arrstr = [];
        this.ImgFile.forEach((e) => {
          arrstr.push({
            Picname: e.NewName,
            Relationid: this.DetailList.id,
            Saveuno: this.UserNo,
            Savename: this.UserName,
          });
        });
        post("/api/WsmPlanPic", { spicDtos: JSON.stringify(arrstr) },)
          .then((resp) => {
            if (resp.code == 200) {
              this.$refs.upload.clearFiles();
              put(
                "/api/WsmPlanDetail",
                this.DetailList,
              )
                .then((resp) => {
                  if (resp.code == 200) {
                    this.bindDetail();
                    this.dialogVisible = false;
                    this.DetailList = [];
                    this.ImgFile = [];
                  } else {
                    this.$message.error(resp.message);
                  }
                })
            } else {
              this.$message.error("图片上传失败");
            }
          })
      } else {
        put("/api/WsmPlanDetail", this.DetailList)
          .then((resp) => {
            if (resp.code == 200) {
              this.bindDetail();
              this.dialogVisible = false;
              this.DetailList = [];
              this.ImgFile = [];
            } else {
              this.$message.error(resp.message);
            }
          })
      }
    },
    RefrehDetail() {
      get(
        "/api/WsmPlanDetail/GetIsRefreh",
        {
          planid: this.SmallPlanId,
        },
      ).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "恢复成功",
          });
          this.GetIsFinish();
          this.bindDetail();
          this.Cutting = [];
          this.isshow = true;
          this.isSave = true;
        } else {
          this.$message.error("恢复失败");
        }
      });
    },
    UploadFile(file) {
      // var formData = new FormData();
      // formData.append("files", file.file);
      // let config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // axios
      //   .post("https://web.yuntixi.cn/api/UpLoadFiles", formData, config)
      //   .then((res) => {
      //     this.ImgFile.push({
      //       oldName: res.data.otherValue,
      //       NewName: res.data.value,
      //     });
      //   })
      //   .catch((res) => {
      //     console.log(res);
      //   });

      var formData = new FormData();
      formData.append("files", file.file);
      post("/api/UpLoadFiles/UploadSWMImg", formData)
        .then((res) => {
          if (res.code == 200) {
            this.ImgFile.push({
              oldName: res.oldValue,
              NewName: res.value,
            });
          } else {
            this.$message.error(res.message);
          }
        })
    },
    handleRemove(file) {
      var nIndex = this.ImgFile.findIndex((e) => e.oldName == file.name);
      this.ImgFile.splice(nIndex);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.ImgVisible = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      var filterRes = this.ImgFile.filter((f) => f.oldName === file.name);
      if (filterRes.length > 0) {
        this.$message.error("该图片已存在，请勿重复上传!");
        return false;
      }
      return isJPG && isLt2M;
    },
    imgclick(src) {
      this.dialogImageUrl = src;
      this.ImgVisible = true;
    },
    delimg(id) {
      this.$confirm("确定删除该图片?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod("/api/WsmPlanPic?picDto=" + id,)
            .then((resp) => {
              console.log(resp);
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.bindDetailPic(this.DetailList.id);
                this.bindDetail();
              }else{
                this.$message.error(resp.message);
              }
            })
        })
        .catch(() => { 
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
  },
  watch: {
    AuditDescribe: function () {
      this.$nextTick(function () {
        var height = $(".div_DetailHeadb").innerHeight();
        this.DetailHeadHeight = 74 + height + "px";
      });
    },
  },
  /*beforeRouteLeave (to, from, next) {
    if (!this.isSave) {
      this.$confirm(`你有未保存的数据，确定离开页面`, "提示信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
            next()
          })
          .catch(() => {
            next(false)
          });
    }else{
        next()
    }
  },*/
};
</script>

<style scoped>
* {
  margin: 0px;
  padding: 0px;
}

.div_pagecheck {
  width: 100%;
  padding: 20px;
}

.div_but {
  /* padding-left: 43px; */
  height: 40px;
}

.btn_sa {
  color: #ffffff;
  width: 86px;
}

.btn_sa1,
.btn_com {
  background: #368df1;
  color: #ffffff;
  width: 86px;
}

.btn_cj {
  color: #7caffc;
  background: #e9f7ff;
  width: 86px;
}

.btn_cx {
  color: #666666;
  width: 86px;
}

.div_but span {
  display: block;
  text-align: center;
  line-height: 40px;
  float: left;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  border-radius: 4px;
}

.btn_hf {
  color: #666666;
  width: 120px;
}

.div_but span:not(:first-child) {
  margin-left: 12px;
}

.div_listAll {
  /* margin-left: 30px; */
  overflow: auto;
}

.div_pagecheck ::-webkit-scrollbar {
  width: 20px;
  height: 8px;
}

.div_pagecheck ::-webkit-scrollbar-thumb {
  border-radius: 12px;
  border: 6px solid rgba(0, 0, 0, 0);
  box-shadow: 8px 0 0 #a5adb7 inset;
}

.div_pagecheck ::-webkit-scrollbar-thumb:hover {
  box-shadow: 8px 0 0 #4a4a4a inset;
}

.div_list {
  width: 316px;
  height: 373px;
  margin-bottom: 12px;
  border: 1px solid #8f8f8f;
  box-shadow: 0px 2px 1px 0px rgba(72, 72, 72, 0.17);
  border-radius: 4px;
  float: left;
  /* margin-left: 10px; */
  margin-right: 10px;
}

.div_list1 {
  width: 316px;
  height: 227px;
  margin-top: 12px;
  border: 1px solid #8f8f8f;
  box-shadow: 0px 2px 1px 0px rgba(72, 72, 72, 0.17);
  border-radius: 4px;
  float: left;
  margin-left: 10px;
}

.div_cont1 {
  width: 100%;
  height: 189px;
  background: #ffffff;
  border-bottom: 1px solid #8f8f8f;
  float: left;
}

.div_list_head {
  width: 100%;
  height: 37px;
  background: #ffffff;
}

.div_list_head div {
  width: 255px;
  height: 100%;
  float: left;
  text-align: center;
  line-height: 37px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.div_list_head .el-button {
  /* width: 22px; */
  height: 21px;
  background: #cae4ff;
  font-size: 18px;
  margin-left: 14px;
  margin-top: 3px;
}

.div_list_head .el-button i {
  color: #0972e7;
  position: relative;
  right: 9px;
  bottom: 9px;
}

.div_list_hr {
  width: 99.5%;
  border: 1px solid #000000;
}

.span_kf {
  display: block;
  width: 47px;
  height: 26px;
  border: 2px solid #ed0f0f;
  float: left;
  font-size: 16px;
  text-align: center;
  color: #ed0f0f;
  margin-top: 4px;
  line-height: 26px;
  font-weight: bold;
  margin-left: 5px;
}

.span_close {
  display: block;
  width: 61px;
  height: 100%;
  float: left;
  font-size: 24px;
  text-align: center;

  line-height: 37px;
  font-weight: bold;
}

.div_cont {
  width: 100%;
  height: 189px;
  background: #ffffff;
  border-bottom: 2px dashed #8f8f8f;
  float: left;
}

.p_evaItem {
  float: left;
  width: 100%;
  height: 54px;
  line-height: 54px;
  text-align: center;
  font-size: 18px;
  overflow: hidden;
  font-family: Microsoft YaHei;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: bold;
  color: #010101;
}

.p_evaContent {
  float: left;
  width: 90%;
  height: 38px;
  margin-left: 12px;
  line-height: 19px;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

.p_appraise {
  float: left;
  width: 96%;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 12px;
}

.p_appraise span {
  display: block;
  float: left;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #414141;
}

.span_standard {
  width: 170px;
}

.span_basis {
  width: 100px;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  margin-left: 20px;
}

.p_appraise1 {
  float: left;
  width: 96%;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 12px;
  height: 38px;
}

.p_appraise1 span {
  display: block;
  float: left;
  line-height: 19px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #90949c;
}

.span_standard1 {
  overflow: hidden;
  width: 170px;
  height: 100%;
}

.span_basis1 {
  width: 100px;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  margin-left: 20px;
}

.div_db {
  float: left;
  width: 100%;
  height: 140px;
}

.p_kfpic {
  float: left;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 20px;
}

.p_kfpic span {
  display: block;
  float: left;
}

.span_xhx1 {
  margin-left: -60px;
  border-bottom: 2px solid #1e23d8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1e23d8;
}

.span_xhx2 {
  margin-left: -30px;
  border-bottom: 2px solid #1e23d8;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #1e23d8;
}

.span_kfxq {
  margin-left: 12px;
  width: 100px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.span_pic {
  width: 100px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.div_nohg {
  width: 100%;
  float: left;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  height: 51px;
  margin-top: 20px;
}

.div_nohg p {
  float: left;
  width: 80px;
  margin-left: 12px;
}

.div_nohg div {
  float: left;
  width: 218px;
  height: 100%;
  background: #ffffff;
  overflow-x: hidden;
  word-break: break-all;
  line-height: 25px;
}

.notclick {
  pointer-events: none;
}

.DetailHead {
  width: 725px;
  border-bottom: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 9px 0px rgba(151, 151, 151, 0.32);
}

.DetailHeadHr {
  margin: auto;
  width: 719px;
  height: 1px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  margin-top: 13px;
}

.DetailMiddleHr {
  margin: auto;
  width: 719px;
  height: 1px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
}

.DetailMiddle {
  margin-top: 20px;
  width: 725px;
}

.DM_evaContent {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  float: left;
  margin-top: 18px;
  margin-left: 39px;
}

.p_evaSt {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #6d727a;
  margin-left: 39px;
  float: left;
  width: 10%;
  height: 100%;
}

.p_evaStandard {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
  float: left;
  width: 80%;
  height: 100%;
}

.DetailBottom {
  margin-top: 20px;
  width: 725px;
  height: 208px;
}

.DetailHeadLf {
  float: left;
  width: 67px;
  height: 56px;
  background: #ffd746;
  border-radius: 10px;
  text-align: center;
  line-height: 56px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-left: 15px;
  margin-top: 8px;
}

.p_DetailHeadt {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  float: left;
  margin-left: 8px;
  margin-top: 8px;
  width: 87.3%;
  overflow: hidden;
}

.p_DetailHeadm {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #8d95b0;
  float: left;
  margin-left: 8px;
  margin-top: 8px;
}

.div_DetailHeadb {
  float: left;
  width: 87.3%;
  margin-top: 8px;
}

.p_DetailHeadb {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #8d95b0;
  height: 100%;
  float: left;
  margin-left: 8px;
  width: 14%;
}

.span_Headb {
  color: #e55c5c;
  float: left;
  width: 80%;
  height: 100%;
}

.span_Headm {
  border: 2px solid #ed0f0f;
  color: #e55c5c;
  text-align: center;
  height: 19px;
}

.p_DetailPf {
  float: left;
  margin-left: 15px;
  margin-top: 0px;
  width: 100%;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0px;
}

.txt {
  width: 281px;
  height: 36px;
  background: #eef2f1;
  outline: none;
  border: 0;
  margin-left: 3px;
}

.txtarea {
  width: 281px;
  height: 108px;
  background: #eef2f1;
  outline: none;
  border: 0;
  resize: none;
  overflow-x: hidden;
  word-break: break-all;
  margin-left: 3px;
}

.txt:focus,
.txtarea:focus {
  outline: none;
}

.txt ::selection,
.txtarea ::selection {
  background: #eef2f1;
}

.txt ::-moz-selection,
.txtarea ::-moz-selection {
  background: #eef2f1;
}

.p_DetailFz {
  float: left;
  width: 12%;
  padding-left: 39px;
  margin-top: 18px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #6d727a;
  margin-bottom: 0px;
}

.p_AuditScore {
  float: left;
  width: 82%;
  margin-top: 10px;
}

.p_AuditDescribe {
  float: left;
  width: 82%;
  margin-top: 10px;
}

.p_DetailDesc {
  float: left;
  width: 12%;
  padding-left: 39px;
  margin-top: 18px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #6d727a;
}

.div_DetailPic {
  float: left;
  width: 12%;
  height: 100%;
  padding-left: 39px;
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #6d727a;
}

.p_pic {
  float: left;
  margin-bottom: 20px;
  width: 82%;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 74px;
  height: 65px;
  line-height: 68px;
  margin-left: 3px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar {
  width: 100%;
  height: 100%;
  display: block;
}

.avatar-uploader /deep/ .el-upload--picture-card {
  width: 74px;
  height: 65px;
}

.avatar-uploader /deep/ .el-upload {
  width: 74px;
  height: 65px;
  line-height: 65px;
}

.avatar-uploader /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 74px;
  height: 65px;
  line-height: 65px;
}

.avatar-uploader /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 74px;
  height: 65px;
  line-height: 65px;
}

.avatar-uploader /deep/ .avatar {
  width: 74px;
  height: 65px;
}

.ImgList {
  width: 74px;
  float: left;
  margin-left: 3px;
}

.ImgList div {
  float: left;
  width: 74px;
}

.ImgList img {
  width: 100%;
  height: 65px;
  float: left;
}

.span_delete {
  display: block;
  float: left;
  width: 74px;
  margin-bottom: 5px;
  text-align: center;
  background: red;
  color: #ffffff;
}

.imgall {
  float: left;
  width: 100%;
}
</style>
