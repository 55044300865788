<template>
  <div class="RegionManage">
    <div class="_SelectHeader">
      <div class="_Select">
        <span style="width: 65px">公司：</span>
        <el-select
          v-model="compyValue"
          placeholder="请选择"
          @change="changCompyInp"
        >
          <el-option
            v-for="item in cpmpyList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="_Inp">
        <span style="width: 100px">区域名称：</span>
        <el-input placeholder="请输入区域名称" v-model="areaVal1" />
      </div>
      <div class="_Inp">
        <span style="width: 100px">区域编号：</span>
        <el-input placeholder="请输入区域编号" v-model="areaVal2" />
      </div>
      <el-button icon="el-icon-search" @click="getList()"></el-button>
      <el-button
        class="el-icon-document-add"
        type="primary"
        @click="showDialog = true"
        >新增</el-button
      >
    </div>
    <div class="tow-box">
      <div>
        <el-table
          :data="isSearch ? searchLits : regionList"
          style="width: 100%"
          stripe
          :max-height="elementHeight"
        >
          <el-table-column type="index" label="序号" width="80">
          </el-table-column>
          <el-table-column prop="areaNo" label="区域编号"> </el-table-column>
          <el-table-column prop="areaName" label="区域名称"> </el-table-column>
          <el-table-column prop="saveTime" label="保存时间">
            <template slot-scope="scope">
              <span>{{ scope.row.saveTime.split("T")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i
                class="el-icon-delete"
                @click="deleteItem(scope.row, scope.$index)"
              ></i>
              <i class="el-icon-edit" @click="editItem(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <Dialog
      :showDialog="showDialog"
      @handleClose="cancle"
      @handleReqData="addRegion"
      :title="isAdd ? '添加区域' : '修改区域'"
      width="21%"
    >
      <div>
        <div class="_Inp">
          <span>区域名称：</span>
          <el-input placeholder="请输入" v-model="areaName" />
        </div>
        <div class="_Inp">
          <span>区域编号：</span>
          <el-input placeholder="请输入" v-model="areaNo" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      areaVal1: "",
      areaVal2: "",
      areaNo: "",
      areaName: "",
      tableData: [],
      value: "",
      regionList: [],
      isAdd: true,
      rowItem: {},
      searchLits: [],
      isSearch: false,
      cpmpyList: [],
      compyValue: "",
      showDialog: false,
    };
  },
  mounted() {
    this.getElementHeight();
    this.getCompy();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 10 + 20 + 64 + 40 + 30);
      });
    },
    getCompy() {
      if (this.$store.state.tenantId == 0) return;
      this.cpmpyList = this.$store.state.companyList;
      this.compyValue = JSON.parse(
        sessionStorage.getItem("userInfo")
      ).upCompanyId;
      // this.getRegionInfo();
      this.searchList();
    },
    changCompyInp() {
      // this.getRegionInfo();
      this.searchList();
    },
    getList() {
      this.regionList = [];
      // !this.areaVal1 && !this.areaVal2
      //   ? this.getRegionInfo()
      //   : this.searchList();
      this.searchList();
    },
    searchList() {
      get(
        "/api/Area/GetByArea?AreaNo=" +
          this.areaVal2 +
          "&AreaName=" +
          this.areaVal1 +
          "&CompId=" +
          this.compyValue
      ).then((resp) => {
        if (resp.code == 200) {
          this.regionList = resp.data;
        }
      });
    },
    getRegionInfo() {
      this.areaVal1 = "";
      this.areaVal2 = "";
      get("/api/Area/GetByCompId?CompId=" + this.compyValue).then((resp) => {
        if (resp.code == 200) {
          this.regionList = resp.data;
        }
      });
    },

    cancle() {
      this.isAdd = true;
      this.areaNo = "";
      this.areaName = "";
      this.showDialog = false;
    },
    addRegion() {
      if (this.areaName == "") return;
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        areaNo: this.areaNo,
        areaName: this.areaName,
        upCompanyId: this.compyValue,
        isDelete: 0,
        // saveTime: this.isAdd ? new Date().toISOString : this.rowItem.saveTime,
        saveTime: new Date().toISOString,
        saveUserId: this.$store.state.userInfo.id + "",
      };
      var methods = this.isAdd ? post : put;
      methods("/api/Area", data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "添加成功" : "修改成功",
            });
            this.cancle();
            // this.getRegionInfo();
            this.searchList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "添加失败" : "修改失败");
        });
    },
    deleteItem(row, index) {
      this.$confirm("您确认要删除" + row.areaName + "?")
        .then(() => {
          deleteMethod("/api/Area?Id=" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.regionList.splice(index, 1);
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    editItem(row) {
      this.showDialog = true;
      this.isAdd = false;
      this.rowItem = row;
      this.areaName = row.areaName;
      this.areaNo = row.areaNo;
    },
  },
};
</script>

<style lang="less" scoped>
.RegionManage {
  .tow-box {
    > div {
      margin-top: 10px;
      padding-left: 10px;
      > p {
        border-left: 5px solid #5a8bff;
        padding-left: 5px;
        margin: 5px 0 15px 0;
      }
      .el-button {
        margin-left: 70px;
        margin-top: 10px;
      }
      .el-button:nth-of-type(1) {
        margin-left: 70px;
        margin-top: 10px;
      }
      .el-button:nth-of-type(2) {
        margin-left: 10px;
        margin-top: 10px;
      }
    }
    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: transparent !important;
    }
    .el-table {
      & i {
        border-radius: 50%;
        border: 1px solid silver;
        padding: 5px;
        margin: 0 5px;
      }
      & i:nth-of-type(1) {
        background-color: #fef9f9;
        color: red;
        border: 1px solid #d3b2b2;
      }
      & i:nth-of-type(2) {
        background-color: #eff3fa;
        color: #5a8bff;
        border: 1px solid #b2c7d3;
      }
    }
  }
  ._SelectHeader {
    position: relative;
    .el-icon-document-add {
      position: absolute;
      right: 0px;
    }
    ._Inp {
      width: 300px !important;
      > span {
        width: 180px !important;
      }
    }
  }
}
</style>
