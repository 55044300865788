import { render, staticRenderFns } from "./RegionManage.vue?vue&type=template&id=075406c9&scoped=true"
import script from "./RegionManage.vue?vue&type=script&lang=js"
export * from "./RegionManage.vue?vue&type=script&lang=js"
import style0 from "./RegionManage.vue?vue&type=style&index=0&id=075406c9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "075406c9",
  null
  
)

export default component.exports