<template>
  <div class="container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" style="position: relative">
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryParams.type" placeholder="请选择类型" @change="changeType">
          <el-option
            v-for="item in [{ value: 0, label: '制造业'},{ value: 1, label: '服务业'}]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="position: absolute;right:0px;margin-right:0px">
        <el-button class="el-icon-document-add" type="primary" @click="handleAdd">新增</el-button>
      </el-form-item>
    </el-form>
    <div class="tableBox">
      <el-table :data="tableData" border :span-method="objectSpanMethod" height="100%">
        <el-table-column prop="sortName" label="一级评价条款"></el-table-column>
        <el-table-column prop="subSortName" label="二级评价条款"></el-table-column>
        <el-table-column prop="preSetSore" label="二级评价条款分值"></el-table-column>
        <el-table-column prop="options" label="二级评价条款描述" width="400">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.options }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="optionsRemark" label="二级评价条款描述举例" width="400">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.optionsRemark }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="optionsValue" label="分级比例">
          <template slot-scope="scope">{{scope.row.optionsValue + '%'}}</template>
        </el-table-column>
        <el-table-column width="150" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
            >修改</el-button>
            <el-button
              style="margin-left:0px"
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :title="title" :visible.sync="dialogVisible " width="400px">
      <el-form :model="form" ref="questionForm" label-width="auto">
        <el-form-item label="类型" prop="classTag">
          <el-select v-model="form.classTag" placeholder="请选择类型" @change="changeType">
            <el-option
              v-for="item in [{ value: 0, label: '制造业'},{ value: 1, label: '服务业'}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="一级评价条款" prop="sortName">
          <el-input v-model="form.sortName" placeholder="请输入一级评价条款" />
        </el-form-item>
        <el-form-item label="二级评价条款" prop="subSortName">
          <el-input v-model="form.subSortName" placeholder="请输入二级评价条款" />
        </el-form-item>
        <el-form-item label="二级评价条款分值" prop="preSetSore">
          <el-input v-model="form.preSetSore" placeholder="请输入二级评价条款分值" />
        </el-form-item>
        <el-form-item label="二级评价条款描述" prop="options">
          <el-input type="textarea" v-model="form.options" placeholder="请输入二级评价条款描述" />
        </el-form-item>
        <el-form-item label="二级评价条款描述举例" prop="optionsRemark">
          <el-input type="textarea" v-model="form.optionsRemark" placeholder="请输入二级评价条款描述举例" />
        </el-form-item>
        <el-form-item label="分级比例(%)" prop="optionsValue">
          <el-select v-model="form.optionsValue" placeholder="请输入分级比例">
            <el-option
              v-for="item in [{ value: 0},{ value: 40},{ value: 60},{ value: 80},{ value: 100}]"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, del } from "../../api/http.js";
export default {
  data() {
    return {
      tableData: [],
      queryParams: {
        type: 0,
      },
      form: {},
      title: "",
      dialogVisible: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      get(
        "/api/EvaluateQuestion/GetAllByClass?calss=" + this.queryParams.type
      ).then((res) => {
        this.tableData = res.data;
      });
    },
    changeType(e) {
      this.queryParams.type = e;
      this.getList();
    },
    // 表单重置
    reset() {
      this.form = {
        id: 0,
        classTag: null,
        options: null,
        optionsRemark: null,
        optionsValue: null,
        preSetSore: null,
        sortName: null,
        subSortName: null,
      };
      // this.$nextTick(() =>{
      //   this.$refs["questionForm"].resetFields();
      // })
    },
    handleAdd() {
      this.reset();
      this.dialogVisible = true;
      this.title = "新增";
    },
    handleUpdate(row) {
      this.dialogVisible = true;
      this.title = "修改";
      this.form = row;
    },
    handleDelete(row) {
      this.form = row;
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          del('/api/EvaluateQuestion/' + row.id,row).then(res =>{
            if(res.code == 200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getList();
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },
    submitForm() {
      if (this.form.id) {
        put('/api/EvaluateQuestion/' + this.form.id, this.form).then(res =>{
          if(res.code == 200){
            this.$message.success('修改成功')
            this.dialogVisible = false
            this.getList()
          }
        })
      } else {
        post('/api/EvaluateQuestion', this.form).then(res =>{
          if(res.code == 200){
            this.$message.success('新增成功')
            this.dialogVisible = false
            this.getList()
          }
        })
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        //定位到维度列
        // 获取当前单元格的值
        const currentValue = row[column.property];
        // 获取上一行相同列的值
        const preRow = this.tableData[rowIndex - 1];
        const preValue = preRow ? preRow[column.property] : null;
        // 如果当前值和上一行的值相同，则将当前单元格隐藏
        if (currentValue === preValue) {
          return { rowspan: 0, colspan: 0 };
        } else {
          // 否则计算当前单元格应该跨越多少行
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.tableData.length; i++) {
            const nextRow = this.tableData[i];
            const nextValue = nextRow[column.property];
            if (nextValue === currentValue) {
              rowspan++;
            } else {
              break;
            }
          }
          return { rowspan, colspan: 1 };
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  ::v-deep .el-form-item {
    margin-bottom: 16px !important;
  }
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .tableBox {
    width: 100%;
    flex: 1;
    ::v-deep .el-table__row td {
      border-bottom: 1px solid #ebeef5 !important;
    }
  }
}
</style>