<template>
  <div>
    <div>
      <div class="select-header">
        <div class="_SelectHeader">
          <div class="_Select">
            <span>公司：</span>
            <el-select v-model="compId" clearable @change="togetArea" placeholder="请选择">
              <el-option
                v-for="item in option1"
                :key="item.value"
                :label="item.companyName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Inp">
            <span>部门：</span>
            <el-select v-model="deptId" clearable placeholder="请选择">
              <el-option
                v-for="item in option2"
                :key="item.value"
                :label="item.deptmentName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>年份：</span>
            <el-date-picker
              v-model="year"
              format="yyyy"
              value-format="yyyy"
              type="year"
              placeholder="选择年"
            ></el-date-picker>
          </div>
          <el-button type="success" icon="el-icon-search" @click="searchList">搜索</el-button>
        </div>
        <div>
          <el-tooltip class="item" effect="dark" content="导入文件格式为xlsx" placement="top">
            <el-button
              style="margin-right: 10px"
              type="primary"
              class="_ImgBtn"
              @click="uploadFile"
            >
              <img src="../../assets/images/import2.png" style="margin: 0" />
            </el-button>
          </el-tooltip>
          <input
            type="file"
            @change="importFile(this)"
            id="imFile"
            ref="fileBtn"
            style="display: none"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
          <el-button type="primary" class="_ImgBtn" @click="exportExcel">
            <img src="../../assets/images/import1.png" style="margin: 0" />
          </el-button>
          <el-button class="_ImgBtn" icon="el-icon-delete" @click="deleteItem"></el-button>
          <el-button class="_ImgBtn" icon="el-icon-edit" @click="editItem"></el-button>
          <el-button type="primary" class="_ImgBtn" @click="addQuestion">
            <img src="../../assets/images/add2.png" />添加题库
          </el-button>
          <el-button class="_ImgBtn" type="primary" @click="deleteItem2">批量删除</el-button>
        </div>
      </div>
      <div class="_TableBox">
        <el-table
          :data="questionList"
          style="width: 100%"
          stripe
          border
          :height="elementHeight"
          v-loading="loading"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick"
          id="table"
        >
          <el-table-column type="selection" width="80">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked"></el-checkbox>
            </template>
          </el-table-column>
          <!-- 编号-名称 -->
          <!-- <el-table-column prop="name" label="岗位名称" width="180">
          </el-table-column>-->
          <el-table-column prop="years" label="审核年份" width="180"></el-table-column>
          <!-- <el-table-column prop="companyName" label="公司" width="180"> </el-table-column> -->
          <el-table-column prop="fileNo" label="文件编号"></el-table-column>
          <el-table-column prop="fileName" label="文件名称"></el-table-column>
          <el-table-column prop="questionRemark" label="问题描述">
            <template slot-scope="scope">
              <div style="text-align: left">{{ scope.row.questionRemark }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="judge" label="判断"></el-table-column>
          <el-table-column prop="improvement" label="纠正措施">
            <template slot-scope="scope">
              <div style="text-align: left">{{ scope.row.improvement }}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="" label="必选">
            <template slot-scope="scope">
              <span>{{ scope.row.isRequired == 1 ? "√" : "" }}</span>
            </template>
          </el-table-column>-->
        </el-table>
        <el-table
          :data="questionList2"
          style="display: none"
          :height="250"
          v-loading="loading"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-click="rowClick"
          id="tableData2"
        >
          <el-table-column prop="plateName" label="板块" width="80" v-if="isShow">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked"></el-checkbox>
            </template>
          </el-table-column>
          <!-- 编号-名称 -->
          <!-- <el-table-column prop="name" label="岗位名称" width="180">
          </el-table-column>-->
          <el-table-column prop="companyName" label="公司" width="180"></el-table-column>
          <el-table-column prop="deptName" label="部门"></el-table-column>
          <el-table-column prop="functionName" label="职能"></el-table-column>
          <el-table-column prop="auditType" label="审核类型">
            <template slot-scope="scope">
              <span>{{ scope.row.auditType == 0 ? "内审" : "外审" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="fileNo" label="文件编号"></el-table-column>
          <el-table-column prop="fileName" label="文件名称"></el-table-column>
          <el-table-column prop="questionRemark" label="问题描述"></el-table-column>
          <el-table-column prop="judge" label="判断"></el-table-column>
          <el-table-column prop="improvement" label="改进措施"></el-table-column>
          <el-table-column prop="years" label="年份"></el-table-column>
          <!-- <el-table-column prop="" label="必选">
            <template slot-scope="scope">
              <span>{{ scope.row.isRequired == 1 ? "√" : "" }}</span>
            </template>
          </el-table-column>-->
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 30, 50, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listCount"
          class="_Pagination"
        ></el-pagination>
      </div>
    </div>
    <Dialog
      :title="isAdd ? '添加' : '修改'"
      :showTowText="showBtnText"
      width="750px"
      top="20vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
    >
      <el-form :model="postData" ref="ruleForm" :rules="rules" label-width="100px">
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="文件编号：" prop="fileNo">
              <el-input placeholder="请输入文件编号" v-model="postData.fileNo"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="文件名称：" prop="fileName">
              <el-input placeholder="请输入文件名称" v-model="postData.fileName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="公司：" prop="compId">
              <el-select
                v-model="postData.compId"
                collapse-tags
                @change="togetArea"
                placeholder="请选择公司"
              >
                <el-option
                  v-for="item in option1"
                  :key="item.value"
                  :label="item.companyName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="部门：" prop="deptId">
              <el-select
                v-model="postData.deptId"
                collapse-tags
                placeholder="请选择部门"
                @change="changDialogDept"
              >
                <el-option
                  v-for="item in option2"
                  :key="item.value"
                  :label="item.deptmentName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="职能：" prop="functionId">
              <el-select v-model="postData.functionId" placeholder="请选择职能">
                <el-option
                  v-for="item in funOption"
                  :key="item.functionId"
                  :label="item.functionName"
                  :value="item.functionId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="审核类别：" prop="auditType">
              <el-select v-model="postData.auditType" placeholder="请选择审核类别">
                <el-option
                  v-for="item in [{id:0,auditName:'内审'},{id:1,auditName:'外审'}]"
                  :key="item.id"
                  :label="item.auditName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="审核年份：" prop="years">
              <el-date-picker
                v-model="postData.years"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年份"
                style="width: 100%;"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="模块：" prop="plateName">
              <el-select v-model="plateName" multiple collapse-tags placeholder="请选择模块">
                <el-option
                  v-for="item in moduleList"
                  :key="item.id"
                  :label="item.plate"
                  :value="item.plate"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="判断：" prop="judge">
              <el-input placeholder="请输入判断" v-model="postData.judge"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="24">
            <el-form-item label="问题描述：" prop="questionRemark">
              <el-input type="textarea" placeholder="请输入问题描述" v-model="postData.questionRemark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between">
          <el-col :span="24">
            <el-form-item label="纠正措施：" prop="improvement">
              <el-input type="textarea" placeholder="请输入纠正措施" v-model="postData.improvement"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="20%">
      <div class="_Inp" style="margin-left: -20px">
        <span>年份：</span>
        <el-input placeholder="请输入" v-model="years"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toSure">确定删除</el-button>
        <el-button @click="dialogVisible = false">取消删除</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
import XLSX from "xlsx";
import { exportExcel } from "../../modules/ExportExcel";

let than = this;
export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      myHeaders: { "Access-Control-Allow-Origin": "*" },
      showDialog: false,
      fileNameInp: "",
      imageUrl: [],
      loading: false,
      isAdd: true,
      showBtnText: false,
      rowItem: {},
      tableRowIndex: null,
      importDataList: [],
      // 搜索
      fileNoInp: "",
      questionList2: [],
      questionList: [],
      isSearch: false,
      year: new Date().getFullYear() + "",
      // 参数
      postData: {
        compId: null,
        deptId: null,
        functionId: null,
        fileNo: "",
        fileName: "",
        questionRemark: "",
        judge: "",
        improvement: "",
        years: "",
        auditType: null,
        isDelete: 0,
        saveUserId: "",
      },
      plateName: "",
      // 分页
      currentPage: 1,
      currentPage1: 1,
      pageSize: 20,
      listCount: 0,
      //
      auditTypeName: [],
      areaName: [],
      postName: [],
      option1: [],
      option2: [],
      option3: [],
      options: [],
      funOption: [],
      value: "",
      compId: "",
      deptId: "",
      years: "",
      list: [],
      Num: 0,
      dialogVisible: false,
      isSearch2: false,
      isShow: true,
      moduleList: [],
      rules: {
        compId: [{ required: true, message: "请选择公司", trigger: "change" }],
        deptId: [{ required: true, message: "请选择部门", trigger: "change" }],
        auditType: [
          { required: true, message: "请选择审核类型", trigger: "change" },
        ],
        years: [
          { required: true, message: "请选择审核年份", trigger: "change" },
        ],
        judge: [{ required: true, message: "请输入判断", trigger: "blur" }],
        questionRemark: [
          { required: true, message: "请输入问题描述", trigger: "blur" },
        ],
        improvement: [
          { required: true, message: "请输入纠正措施", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.option1 = this.$store.state.companyList;
  },
  mounted() {
    this.imFile = document.getElementById("imFile");
    this.getQuestionList();
    this.getElementHeight();
    this.getmoduleList();
  },
  filters: {
    FcompanyName(e) {
      than.option1.forEach((i) => {
        if (e === i.id) {
          return i.companyName;
        }
      });
    },
  },
  methods: {
    getmoduleList() {
      get("/api/Plate").then((res) => {
        this.moduleList = res.data;
      });
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 134);
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getQuestionList();
    },
    handleCurrentChange(val) {
      if (this.isSearch2) {
        this.currentPage = val;
        this.currentPage1 = val;
        this.searchList();
      } else {
        this.currentPage = val;
        this.getQuestionList();
      }
    },
    rowClick(row) {
      this.rowItem = row;
      console.log(row, 77);
      this.tableRowIndex = row.index;
      this.questionList.map((item) => {
        item.checked = false;
        if (row.id == item.id) {
          row.checked = true;
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    getQuestionList() {
      get(
        "/api/QuestionPoolPlate/GetAllQuestionByYear?page=" +
          this.currentPage +
          "&limit=" +
          this.pageSize +
          "&year=" +
          this.year
      ).then((res) => {
        if (res.code == 200) {
          res.data.forEach((i) => {
            this.option1.forEach((e) => {
              if (i.compId == e.id) {
                i.companyName = e.companyName;
              }
            });
          });
          this.listCount = res.count; //总条数未修改
          this.questionList = res.data;
        }
      });
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    changInpVal() {
      if (this.fileNoInp == "" && this.fileNameInp == "") {
        this.isSearch = false;
        this.getQuestionList();
      }
    },
    handleClose() {
      this.showDialog = false;
      this.imageUrl = [];
    },
    searchList() {
      this.isSearch2 = true;
      if (this.compId === "" && (this.year === "" || this.year === null)) {
        this.$message({
          type: "warning",
          message: "请填写公司和年份",
        });
        this.isSearch2 = false;
        return;
      }
      this.questionList = [];
      this.currentPage = 1;
      this.currentPage = this.currentPage1;
      if (this.compId && this.year) {
        this.url =
          "/api/QuestionPoolPlate/GetAllQuestionByYearByComp?compid=" +
          this.compId +
          "&DeptId=" +
          this.deptId +
          "&year=" +
          this.year;
      } else if (this.compId && this.deptId && !this.year) {
        this.$message({
          type: "warning",
          message: "请选择年份",
        });
      } else if (this.compId && !this.deptId) {
        this.url =
          "/api/QuestionPoolPlate/GetAllQuestionByComp?compid=" + this.compId;
      } else if (this.year) {
        this.url =
          "/api/QuestionPoolPlate/GetAllQuestionByYear?year=" + this.year;
      }
      get(this.url + "&page=1" + "&limit=999999")
        .then((res) => {
          this.listCount = res.data.length;
        })
        .then(() => {
          get(
            this.url + "&page=" + this.currentPage + "&limit=" + this.pageSize
          ).then((res) => {
            this.questionList = res.data;
          });
        });
    },
    beforeAvatarUpload(file) {
      if (file.type === "image/png" || file.type === "image/jpeg") {
        console.log("等于", file.type);
      } else {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl.push(URL.createObjectURL(file.raw));
    },
    getfile(file) {
      if (file.response == undefined) return;
      if (this.imageAddr.length == 0) {
        this.imageAddr = file.response.value; //图片上传到服务器，请求后返回的图片路径
      } else {
        this.imageAddr = this.imageAddr + "," + file.response.value;
      }
    },
    // 增删改
    handleReqData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.postData.functionId =
            this.postData.functionId == "" ? 0 : this.postData.functionId;
          var methods = this.isAdd ? post : put;
          methods(
            "/api/QuestionPoolPlate?Plate=" + this.plateName.toString(),
            this.postData
          )
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: this.isAdd ? "添加成功" : "修改成功",
                });
                this.showDialog = false;
                this.isSearch = false;
                // this.getQuestionList();
                this.compId = this.postData.compId;
                this.deptId = this.postData.deptId;
                this.year = this.postData.years;
                this.searchList();
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error(this.isAdd ? "添加失败" : "修改失败");
            });
        }
      });
    },
    addQuestion() {
      this.showDialog = true;
      this.isAdd = true;
      this.showBtnText = false;
      this.postData.compId = null;
      this.postData.deptId = null;
      this.postData.functionId = null;
      this.postData.fileNo = "";
      this.postData.fileName = "";
      this.postData.questionRemark = "";
      this.postData.improvement = "";
      this.postData.years = "";
      this.postData.auditType = null;
      this.postData.isDelete = 0;
      this.postData.saveUserId = "";
      this.$nextTick(() => {
        this.$refs["ruleForm"].resetFields();
      });
    },
    editItem() {
      if (this.tableRowIndex == null) return;
      this.isAdd = false;
      this.showBtnText = true;
      this.showDialog = true;
      this.postData = this.rowItem;
      this.postData.functionId =
        this.postData.functionId == 0 ? "" : this.postData.functionId;
      // this.togetArea(this.rowItem.compId);
      get("/api/Department/GetNoTree?CompId=" + this.rowItem.compId).then(
        (res) => {
          this.option2 = res.data;
        }
      );
      get(
        "/api/PostAndFile/GetFunctionList?CompId=" +
          this.postData.compId +
          "&DeptId=" +
          this.rowItem.deptId
      ).then((res) => {
        this.funOption = res.data;
      });
    },
    deleteItem() {
      if (this.tableRowIndex == null) return;
      this.$confirm("您确认要删除“" + this.rowItem.fileName + "”这个文件？")
        .then(() => {
          deleteMethod("/api/QuestionPoolPlate?id=" + this.rowItem.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.questionList.splice(this.tableRowIndex, 1);
                this.tableRowIndex = null;
                this.rowItem = {};
                this.searchList();
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    deleteItem2() {
      this.dialogVisible = true;
    },

    // 导入excel
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.questionList2 = [];
          this.$nextTick(() => {
            exportExcel("#tableData2", "标准格式文件.xlsx");
          });
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    toSure() {
      deleteMethod(
        "/api/QuestionPoolPlate/DeleteByYear?year=" + this.years
      ).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.message,
          });
          this.dialogVisible = false;
          // this.getQuestionList();
          this.searchList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    importFile: function () {
      var importDataList = [];
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      this.list = [];
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        excelData.map((item) => {
          //console.log(item);
          importDataList.push({
            plateName: item.板块,
            companyName: item.公司,
            deptName: item.部门,
            functionName: item.职能,
            fileNo: item.文件编号 + "",
            fileName: item.文件名称,
            questionRemark: item.问题描述 + "",
            judge: item.判断,
            improvement: item.改进措施,
            years: item.年份 + "",
            auditType: item.审核类型 == "内审" ? 0 : 1,
            // isRequired: item.必选 ? 1 : 0,
          });
        });
        if (importDataList.length != 0) {
          //this.list.push(importDataList);
          //console.log(importDataList);
          $this.importQuestion(importDataList);
        } else {
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
          this.$refs.fileBtn.value = null;
        }
      };
      console.log(this.list);
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    togetArea(e) {
      this.postData.deptId = "";
      this.postData.functionId = "";
      this.deptId = "";
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        this.option2 = res.data;
        if (this.isAdd) {
          this.rowItem.deptId = "";
        }
      });
    },
    // 选择职能
    changDialogDept(e) {
      this.postData.functionId = "";
      get(
        "/api/PostAndFile/GetFunctionList?CompId=" +
          this.postData.compId +
          "&DeptId=" +
          e
      ).then((res) => {
        this.funOption = res.data;
      });
    },
    importQuestion(list) {
      this.loading = true;
      post("/api/QuestionPoolPlateImport", list)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "导入成功",
            });
            this.loading = false;
            this.$refs.fileBtn.value = null;
            this.getQuestionList();
          } else {
            this.$message.error(resp.message);
            this.loading = false;
            this.$refs.fileBtn.value = null;
          }
        })
        .catch(() => {});
      // this.Num = 0;
      // this.endNum = 1;
      // if (this.endNum > list.length) this.endNum = list.length;
      // this.Postlist(this.Num, this.endNum, list);
    },
    async Postlist(Num, endNum, list) {
      this.$message({
        duration: 0,
        type: "success",
        iconClass: "el-icon-loading",
        message: "导入中......." + (Num + "/" + list.length),
      });
      await post("/api/QuestionPoolPlateImport", list.slice(Num, endNum))
        .then((resp) => {
          if (resp.code == 200) {
            this.loading = false;
            this.getQuestionList();
            this.$message.closeAll();
            if (resp.message !== "成功！") {
              console.log(resp.message);
              console.log(list.slice(Num, endNum));
            }
            if (endNum === list.length) {
              this.$message({
                type: "success",
                message: "全部导入完成",
              });
              return;
            }
          } else {
            this.$message.error(resp.message);
          }
          this.Num += 1;
          this.endNum += 1;
          if (this.endNum > list.length) {
            this.endNum = list.length;
          }
          this.Postlist(this.Num, this.endNum, list);
        })
        .catch(() => {
          this.$message.error("导入失败");
        });
    },
    // 导出
    exportExcel() {
      if (this.compId === "" || this.year === "") {
        this.$message({
          type: "warning",
          message: "请选择公司和年份",
        });
        return;
      }
      get(
        "/api/QuestionPoolPlateImport?compid=" +
          this.compId +
          "&year=" +
          this.year
      )
        .then((res) => {
          this.isShow = false;
          this.questionList2 = res.data;
          this.searchList();
        })
        .then(() => {
          return exportExcel("#tableData2", "内外审问题点题库.xlsx");
        })
        .then(() => {
          this.isShow = true;
        });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-form-item__error {
  position: relative !important;
}
::v-deep .el-dialog .el-form-item {
  margin-bottom: 10px !important;
}
.dialog ._Select {
  width: 100% !important;
}

.dialog .el-select {
  width: 100%;
  // margin-top: 10px;
}

.dialog .contaion > div:nth-of-type(1) {
  display: flex;
}

.dialog .contaion > div:nth-of-type(1) div {
  padding-right: 30px;
}

.dialog ._Select > span,
.dialog ._Inp > span {
  width: 170px;
}

.select-header {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
}

/* 上传图片 */
.avatar-uploader {
  margin-left: 38px;
  padding-right: 30px;
  padding-top: 20px;
}

/deep/ .avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #b4b3b3;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px dotted #b4b3b3;
  border-radius: 2px;
  margin-top: 4px;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
  margin-right: 4px;
  margin-top: 4px;
}

._Inp1 .el-select {
  width: 175px;
}

._Inp .el-select {
  margin-top: 0;
  height: auto !important;
  display: flex !important;
}

._Inp .el-select > span {
  width: auto !important;
}

._Inp1 {
  margin-left: 11.3%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._Inp1 input {
  display: flex;
  height: auto;
}

.yearBox {
  .el-date-editor {
    ::v-deep .el-input__suffix {
      right: 30px !important;
    }
  }
}
</style>
