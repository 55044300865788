<template>
  <div class="container">
    <el-form :model="queryParams" ref="queryForm" :inline="true">
      <el-form-item label="标准:">
        <el-select v-model="queryParams.standard" placeholder="请选择" clearable>
          <el-option
            v-for="item in standardList"
            :key="item.id"
            :label="item.standardName"
            :value="item.standardName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" @click="handleSearch"></el-button>
      </el-form-item>
      <el-form-item class="butFormItem">
        <el-tooltip class="item" effect="dark" content="导入文件格式为xlsx" placement="top">
          <el-button style="margin-right: 10px" type="primary" class="_ImgBtn" @click="uploadFile">
            <img src="../../assets/images/import2.png" />
          </el-button>
        </el-tooltip>
        <input
          type="file"
          @change="importFile(this)"
          id="imFile"
          ref="fileBtn"
          style="display: none"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <el-button type="primary" class="_ImgBtn" @click="exportExcel">
          <img src="../../assets/images/import1.png" />
        </el-button>
        <el-button class="_ImgBtn" icon="el-icon-delete" :disabled="multiple" @click="handleDelete"></el-button>
        <el-button class="_ImgBtn" icon="el-icon-edit" :disabled="single" @click="handleUpdate"></el-button>
        <el-button class="_ImgBtn" type="primary" @click="handleAdd">
          <img src="../../assets/images/add2.png" />
          添加题库
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="loading"
      id="tableData"
      :data="tableData"
      stripe
      border
      highlight-current-row
      style="width: 100%"
      :height="elementHeight"
      :row-class-name="tableRowClassName"
      :row-style="selectedstyle"
      @selection-change="handleSelectionChange"
      @row-click="rowClick"
    >
      <el-table-column type="selection" width="55" v-if="isShow"></el-table-column>
      <el-table-column
        v-if="isHide"
        type="index"
        label="序号"
        align="center"
        width="80"
        :index="(e) => (queryParams.page - 1) * queryParams.limit + e + 1"
      />
      <el-table-column prop="standard" label="标准"></el-table-column>
      <el-table-column prop="term" label="条款"></el-table-column>
      <el-table-column prop="question" label="问题">
        <template slot-scope="scope">
          <div style="text-align: left">{{ scope.row.question }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="answer" label="检查标准"></el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryParams.page"
      :page-sizes="[10, 20, 50]"
      :page-size="queryParams.limit"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      class="_Pagination"
      v-show="count"
    ></el-pagination>

    <el-dialog :title="title" :visible.sync="open" width="400px" append-to-body>
      <el-form ref="form" :model="form" label-width="auto" class="form">
        <el-form-item label="标准" prop="standardId">
          <el-select
            v-model="form.standardId"
            placeholder="请选择标准"
            clearable
            @change="changeStandard"
          >
            <el-option
              v-for="item in standardList"
              :key="item.id"
              :label="item.standardName"
              :value="String(item.id)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="条款" prop="term">
          <el-input v-model="form.term" placeholder="请输入条款" />
        </el-form-item>
        <el-form-item label="问题" prop="question">
          <el-input type="textarea" v-model="form.question" placeholder="请输入问题" />
        </el-form-item>
        <el-form-item label="检查标准" prop="answer">
          <el-input type="textarea" v-model="form.answer" placeholder="请输入检查标准" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put } from "../../api/http.js";
import { exportExcel } from "../../modules/ExportExcel";
import XLSX from "xlsx";
export default {
  data() {
    return {
      loading: false,
      elementHeight: 0,
      isShow: true,
      isHide: true,
      ids: [],
      single: true,
      multiple: true,
      tableData: [],
      count: 0,
      standardList: [],
      title: "",
      form: {},
      open: false,
      tableRowIndex: null,
      queryParams: {
        standard: "",
        limit: 20,
        page: 1,
      },
    };
  },
  created() {
    this.getStandardList();
    this.getList();
  },
  mounted() {
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 56 + 48);
      });
    },
    // 标准列表
    getStandardList() {
      get("api/StandardInfo/GetList").then((res) => {
        this.standardList = res.data;
      });
    },
    getList() {
      get("/api/SupplierQuespool/GetByFilePage", this.queryParams).then(
        (res) => {
          this.tableData = res.data;
          this.count = res.count;
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.dataList = selection;
      this.form = selection[0];
      this.ids = selection.map((item) => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    rowClick(row) {
      this.form = row;
      this.ids = [row.id];
      this.tableRowIndex = row.index;
      this.single = false;
      this.multiple = false;
    },
    handleSearch() {
      this.getList();
    },
    changeStandard(e) {
      this.standardList.filter((item) => {
        if (item.id == e) {
          this.form.standard = item.standardName;
        }
      });
    },
    // 表单重置
    reset() {
      this.form = {
        standardId: "",
        standard: "",
        term: "",
        question: "",
        answer: "",
        isDelete: 0,
        saveTime: new Date().toISOString(),
        saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id,
        tenantId: JSON.parse(sessionStorage.getItem("userInfo")).tenantId,
      };
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    // 新增按钮
    handleAdd() {
      this.reset();
      this.title = "添加题库";
      this.open = true;
    },
    // 修改按钮
    handleUpdate() {
      this.title = "修改题库";
      this.open = true;
    },
    // 提交按钮
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.form.standardId = this.form.standardId.toString();
            put("/api/SupplierQuespool", this.form).then((res) => {
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.open = false;
                this.tableRowIndex = null;
                this.getList();
              }else{
                this.$message.error(res.message);
              }
            });
          } else {
            post("/api/SupplierQuespool", this.form).then((res) => {
              if (res.code == 200) {
                this.$message.success("新增成功");
                this.open = false;
                this.tableRowIndex = null;
                this.getList();
              }else{
                this.$message.error(res.message);
              }
            });
          }
        }
      });
    },
    // 删除
    handleDelete() {
      let list = [];
      this.ids.forEach((item) => {
        list.push({ QuestionId: item });
      });
      let queryParams = {
        data: JSON.stringify(list),
      };
      this.$confirm("此操作将永久删除该题目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          post("/api/SupplierQuespool/BatchDelete", queryParams).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 导入
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.isShow = false;
          this.isHide = false;
          this.tableData = [];
          this.$nextTick(() => {
            exportExcel("#tableData", "供应商审核题库.xlsx");
            this.isShow = true;
            this.isHide = true;
          });
        })
        .then(() => {
          this.getList();
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile.click();
            this.$refs.fileBtn.value = null;
          }
        });
    },
    importFile: function () {
      this.tableData = [];
      var importDataList = [];
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        excelData.map((item) => {
          importDataList.push({
            standard: item.标准,
            term: item.条款 + "",
            question: item.问题,
            answer: item.检查标准 + "",
          });
        });
        if (importDataList.length != 0) {
          $this.tableData = importDataList;
          this.loading = true;
          $this.importQuestion(importDataList);
        } else {
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
        }
      };
      if ($this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    // 验证
    importQuestion(importDataList) {
      post("/api/SupplierQuespool/CheckData", {
        data: JSON.stringify(importDataList),
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("验证成功,开始导入");
          importDataList.map((i, index) => {
            this.importData(i, index);
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 导入数据
    async importData(i, index) {
      await post("/api/SupplierQuespool/BatchAdd", i)
        .then((res) => {
          if (res.code == 200) {
            if (index + 1 == this.tableData.length) {
              this.$message({
                type: "success",
                message: "已全部导入",
              });
              this.getList();
              this.loading = false;
            }
          } else {
            this.$message.error(res.message);
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("系统异常,请稍后再试");
        });
    },
    // 导出
    exportExcel() {
      this.isShow = false;
      this.$nextTick(() => {
        exportExcel("#tableData", "供应商审核题库.xlsx");
        this.isShow = true;
      });
    },
    handleSizeChange(e) {
      this.queryParams.limit = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.queryParams.page = e;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.butFormItem {
  float: right;
  margin-right: 0;
  ::v-deep .el-icon-delete,
  ::v-deep .el-icon-edit {
    font-size: 15px;
  }
}
::v-deep .el-form-item {
  margin-bottom: 16px !important;
}
._Pagination {
  text-align: end;
  padding: 10px 20px;
  background-color: #ffffff;
}
</style>