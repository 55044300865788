<template>
  <div>
    <div style="text-align: end; padding-bottom: 10px">
      <el-button class="el-icon-document-add" type="primary" @click="handAdd"
        >新增</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      :height="elementHeight"
    >
      <el-table-column type="index" label="序号" width="180"> </el-table-column>
      <!-- <el-table-column prop="tenantId" label="企业标准/通用标准"> 
        <template slot-scope="scope">
          <span>{{ scope.row.tenantId === 1 ? '通用标准' : '企业标准' }}</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="standardName" label="标准名称"> </el-table-column>
      <el-table-column prop="standardAbbr" label="标准简称"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            circle
            type="warning"
            icon="el-icon-edit"
            :disabled="scope.row.tenantId === 1 && tenantId != 1"
            @click="editItem(scope.row)"
          ></el-button>
          <el-button
            circle
            type="danger"
            icon="el-icon-delete"
            :disabled="scope.row.tenantId === 1 && tenantId != 1"
            @click="deleteItem(scope.row, scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <Dialog
      :showDialog="showDialog"
      @handleClose="cancle"
      @handleReqData="reqData"
      :title="isAdd ? '添加标准' : '修改标准'"
      width="21%"
    >
      <div>
        <div class="_Inp">
          <span>标准名称：</span>
          <el-input placeholder="请输入" v-model="standardName" />
        </div>
        <div class="_Inp">
          <span>标准简称：</span>
          <el-input placeholder="请输入" v-model="standardAbbr" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { get, put, post, deleteMethod } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
export default {
  components: { Dialog },
  data() {
    return {
      tableData: [],
      elementHeight: 0,
      standardName: "",
      standardAbbr: "",
      showDialog: false,
      isAdd: true,
      dataItem: {},
      tenantId:JSON.parse(sessionStorage.getItem("userInfo")).tenantId,
    };
  },
  mounted() {
    this.getElementHeight();
    this.getDataList();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - 150;
      });
    },
    getDataList() {
      get("/api/StandardInfo/GetList").then((resp) => {
        if (resp.code == 200) {
          this.tableData = resp.data;
        }
      });
    },
    cancle() {
      this.showDialog = false;
    },
    handAdd() {
      this.isAdd = true;
      this.showDialog = true;
      this.standardAbbr = "";
      this.standardName = "";
    },
    editItem(e) {
      this.isAdd = false;
      this.showDialog = true;
      this.dataItem = e;
      this.standardAbbr = e.standardAbbr;
      this.standardName = e.standardName;
    },
    reqData() {
      var methods = this.isAdd ? post : put;
      var data = {
        id: this.isAdd ? 0 : this.dataItem.id,
        standardName: this.standardName,
        standardAbbr: this.standardAbbr,
        tenantId: sessionStorage.getItem("userInfo").tenantId,
        isdelete: 0,
      };
      methods("/api/StandardInfo", data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: (this.isAdd ? "添加" : "修改") + "成功",
            });
            this.showDialog = false;
            this.getDataList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error((this.isAdd ? "添加" : "修改") + "失败");
        });
    },
    deleteItem(row, index) {
      this.$confirm("您确认要删除" + row.standardAbbr + "这个标准?")
        .then(() => {
          deleteMethod("/api/StandardInfo?Id=" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.tableData.splice(index, 1);
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>