<template>
  <div class="container">
    <div class="box">
      <!-- <div class="title">供应商信息登记</div>
        <div class="text">供应商你好，请将您的相关信息填写在输入框中，并提交您的信息~</div> -->
      <div class="mainBox">
        <div class="bottomMain">
          <div class="lableBox">产品信息</div>
          <div class="btnBox">
            <div @click="handelAdd">
              <img src="../../assets/images/addBtn.png" alt />
              <span>新增产品</span>
            </div>
            <img
              src="../../assets/images/delBtn.png"
              style="margin-right: 15px"
              alt
              @click="handleDelete"
            />
            <img
              src="../../assets/images/editBtn.png"
              style="margin-right: 15px"
              alt
              @click="handleUpdate"
            />
            <div @click="handleLink">
              <img src="../../assets/images/linkIcon.png" alt />
              <span class="processFont">工序链接</span>
            </div>
          </div>
          <div class="topMain">
            <el-table
              ref="multipleTable"
              :data="tableData"
              border
              style="width: 100%"
              height="100%"
              :header-cell-style="{ background: '#E8EBF4', color: '#000000' }"
              @row-click="rowClick"
              :row-class-name="tableRowClassName"
              :row-style="selectedstyle"
            >
              <!-- <el-table-column type="selection" width="55">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked"></el-checkbox>
                </template>
              </el-table-column> -->
              <el-table-column type="index" label="序号" width="80">
              </el-table-column>
              <el-table-column
                prop="productName"
                label="产品名称"
              ></el-table-column>
              <el-table-column
                prop="productNo"
                label="产品编号"
              ></el-table-column>
              <el-table-column prop="productType" label="项目类型">
                <template slot-scope="scope">
                  <span v-if="scope.row.productType == 0">过程设计</span>
                  <span v-else-if="scope.row.productType == 1"
                    >产品设计、过程设计</span
                  >
                </template>
              </el-table-column>
              <!-- <el-table-column prop="address" label="产品工序"></el-table-column> -->
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增修改产品弹框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="478px">
      <el-form
        ref="productForm"
        :model="productForm"
        label-width="68px"
        class="addForm"
      >
        <el-form-item label="产品名称">
          <el-input
            v-model="productForm.productName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品编号">
          <el-input
            v-model="productForm.productNo"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目类型">
          <el-select
            v-model="productForm.productType"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in productTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { get, post, put, deleteMethod } from "../../api/http.js";
export default {
  data() {
    return {
      tableData: [],
      productTypeList: [
        {
          label: "过程设计",
          value: "0",
        },
        {
          label: "产品设计、过程设计",
          value: "1",
        },
      ],
      dialogVisible: false,
      productForm: {}, // 新增产品
      typeList: [], // 项目类型列表
      title: "",
      id: null,
      tableRowIndex: null,
      checked1: false,
      checked2: true,
      checked3: true,
      checked4: true,
      checked5: true,
      checked6: true,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    // 查询产品列表
    getList() {
      get("/api/VdaProduct/GetMyList").then((res) => {
        this.tableData = res.data;
      });
    },
    // 跳转工序页面
    handleLink() {
      if (this.id == null) {
        this.$message({
          message: "请选择产品信息",
          type: "warning",
        });
      } else {
        this.$router.push({
          path: "/Process",
          query: {
            id: this.id,
          },
        });
      }
    },
    // 点击当前行
    rowClick(row) {
      this.id = row.id;
      this.productForm = row;
      this.tableRowIndex = row.index;
      //   this.tableData.map((item) => {
      //     item.checked = false;
      //     if (row.id == item.id) {
      //       row.checked = true;
      //     }
      //   });
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#555555",
        };
      }
    },
    // 表单重置
    reset() {
      this.productForm = {
        id: 0,
        supplierId: 0, //自己的传0
        productNo: null,
        productName: null,
        upId: 0,
        productSpec: null,
        productAsk: null,
        remark: null,
        productType: null,
        productStatus: 0,
        tenantId: 0,
        saveUserId: 0,
        saveUserNo: null,
        saveUserName: null,
        saveTime: null,
        isDelete: 0,
      };
      this.$nextTick(() => {
        this.$refs["productForm"].resetFields();
      });
    },
    // 新增产品
    handelAdd() {
      this.reset();
      this.dialogVisible = true;
      this.title = "新增产品";
      this.tableRowIndex = null;
      this.id = null;
      //   this.tableData.map((item) => {
      //     item.checked = false;
      //   });
    },
    // 修改产品
    handleUpdate() {
      if (this.id == null) {
        this.$message({
          message: "请选择需要修改的产品信息",
          type: "warning",
        });
      } else {
        this.dialogVisible = true;
        this.title = "修改产品";
      }
    },
    // 删除产品
    handleDelete() {
      if (this.id == null) {
        this.$message({
          message: "请选择需要删除的产品信息",
          type: "warning",
        });
      } else {
        this.$confirm("此操作将永久删除该产品信息, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            deleteMethod("/api/VdaProduct?id=" + this.id).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.tableRowIndex = null;
                this.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    // 产品提交按钮
    submitForm() {
      if (this.id != null) {
        put("/api/VdaProduct?id=" + this.id, this.productForm).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.dialogVisible = false;
            this.tableRowIndex = null;
            this.id = null; 
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        post("/api/VdaProduct", this.productForm).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "新增成功",
            });
            this.dialogVisible = false;
            this.tableRowIndex = null;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
  },
};
</script>
  
  <style lang="less" scoped>
.container {
  width: 100%;
  height: calc(100vh - 90px);
  background-color: #f1f8fd;
  .box {
    width: 70%;
    height: 100%;
    background-color: #fff;
    margin: 0 auto;
    padding: 46px 140px 26px 54px;
    .title {
      font-size: 20px;
      height: 20px;
      line-height: 20px;
      font-weight: bold;
      color: #050505;
      text-align: center;
      margin-bottom: 16px;
    }
    .text {
      font-size: 14px;
      height: 14px;
      line-height: 14px;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-bottom: 26px;
    }
    .mainBox {
      height: calc(100% - 60px);
      .topMain {
        .formBox {
          flex: 1;
          height: 130px;
          background-color: #f8f9fa;
          padding: 14px 0 0 20px;
          box-sizing: border-box;
          .el-form {
            display: flex;
            flex-wrap: wrap;
            .el-form-item {
              width: calc(100% / 3 - 42px);
              height: 40px;
              margin: 0 14px 10px 0;
              ::v-deep .el-form-item__content {
                width: 100% !important;
                height: 40px !important;
              }
              ::v-deep .el-input-group__prepend {
                background-color: #fff;
              }
              ::v-deep .el-input__inner {
                height: 40px !important;
                border-left: none !important;
                text-align: right;
              }
              ::v-deep .el-input__inner:focus,
              ::v-deep .el-input__inner:active,
              ::v-deep .el-input__inner:hover {
                border-color: #dcdfe6 !important;
              }
            }
          }
        }
      }
      .bottomMain {
        height: calc(100% - 40px);
        .lableBox {
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #3d3d3d;
          i {
            margin-left: 6px;
            font-weight: bold !important;
          }
        }
        .btnBox {
          display: flex;
          align-items: center;
          margin: 12px 0;
          div {
            cursor: pointer;
            margin-right: 15px;
            display: flex;
            align-items: center;
            span {
              font-size: 15px;
              font-weight: bold;
              color: #1361ff;
            }
            .processFont {
              font-family: PingFang SC;
              font-weight: bold;
              color: #515151;
            }
          }
          img {
            cursor: pointer;
            margin-right: 2px;
            width: 25px;
            height: 25px;
          }
        }
        .topMain {
          height: calc(100% - 46px);
          .el-table {
            background: #f8f9fa;
          }
          ::v-deep .el-table__cell {
            background-color: #ffffff;
          }
        }
      }
    }
    .footer {
      margin-top: 5px;
      width: 100%;
      text-align: center;
    }
  }
  .addForm {
    .el-form-item {
      margin-bottom: 10px !important;
      margin-right: 2px;
    }
    ::v-deep .el-input__inner {
      height: 40px !important;
    }
    ::v-deep .el-input {
      width: calc(100% - 22px);
    }
    ::v-deep .el-icon-close {
      margin-left: 8px;
    }
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #babec2;
    border-color: #babec2;
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #5e697c;
  }
  ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #babec2;
  }
}
</style>