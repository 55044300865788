<template>
  <div class="tab">
    <div v-if="tabList.length != 0">
      <div v-for="(item, index) in tabList" :key="index">
        <div
          class="menu"
          @click="clickTabItem(index, item.path)"
          :class="{
            tabActive: newRouter == item.path && !item.children,
            showcolor: index == tabIndex,
          }"
        >
          <span>{{ item.name }} </span
          ><img
            v-if="newRouter == item.path && !item.children"
            src="../../assets/images/to.png"
            class="toPng"
          />
          <i
            :class="show ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
            v-if="item.children"
          ></i>
        </div>
        <div v-for="(item, childIndex) in item.children" :key="childIndex">
          <div
            v-show="show"
            class="menu-item"
            @click="clickTabItemChild(item.path, index)"
            :class="{ tabActive: newRouter == item.path }"
          >
            <span>{{ item.name }}</span>
            <img
              v-if="newRouter == item.path"
              src="../../assets/images/to.png"
              class="toPng"
              style="margin-right: 28px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tpagediv" v-else>
      <div
        :class="{ activePage: index == tabIndexPage }"
        class="toPage"
        v-for="(item, index) in getPageList"
        :key="index"
      >
        <span
          style="font-size: 18px; padding: 0"
          v-if="tabIndexPage == index"
          class="el-icon-place"
          data-icon=""
        ></span>
        <img
          v-if="tabIndexPage != index"
          src="../../assets/images/toPage.png"
          alt=""
        />
        <span @click="clickTabItemPage(index, item.path)">{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tabList", "getPageList"],
  data() {
    return {
      tabIndexPage: 0,
      tabIndex: null,
      newRouter: "",
      show: false,
      isShowchildren: false,
    };
  },
  created() {
    this.newRouter = this.$route.path;
    if (this.newRouter == "/filepage-ofno") {
      this.newRouter = "/plan-page";
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        this.newRouter = val.fullPath;
        if (val.path == "/filepage-ofno") {
          this.newRouter = "/plan-page";
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    clickTabItem(index, path) {
      this.tabList.forEach((item, i) => {
        if (index == i && item.children && item.children.length > 0) {
          this.show = !this.show;
        }
      });
      if(!path) return
      this.$router.push(path);
      this.tabIndex = index;
    },
    clickTabItemChild(path, index) {
      if(!path) return
      this.$router.push(path);
      this.tabIndex = index;
    },
    clickTabItemPage(index, path) {
      this.tabIndexPage = index;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  padding-top: 60px;
  .menu {
    position: relative;
    display: flex;
    align-items: center;
    padding: 15px 0 15px 25px;
    font-weight: bold;
    font-size: 14px;
    &:hover {
      background-color: #eaeef6;
    }
    ::v-deep .el-icon-arrow-up,
    .el-icon-arrow-down {
      position: absolute;
      left: 175px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .toPng {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 20px;
  }
  .menu-item {
    position: relative;
    padding: 15px 0 15px 25px;
    width: 100%;
    font-size: 13px;
    background-color: #eaeef6;
  }
  .tabActive {
    color: #4b85fe;
    border-left: 5px solid #4b85fe;
    background-color: #eaeef6;
    padding: 15px 0 15px 20px;
  }
  .showcolor {
    color: #4b85fe;
  }
}
.tpagediv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.toPage {
  display: flex;
  align-items: center;
  margin-top: 20px;
  background-color: #f2f2f2;
  padding: 8px;
  justify-content: center;
  border: 1px solid silver;
  border-radius: 5px;
  > img {
    width: 18px;
    height: 22px;
  }
  > span {
    color: #656565;
    // border-bottom: 2px solid rgb(92, 92, 92);
    padding: 5px;
    font-weight: bold;
  }
}
.activePage {
  border-color: #4b85fe;
  span {
    color: #4b85fe;
  }
}
</style>