<template>
  <div>
    <div class="_SelectHeader">
      <div class="text" style="margin-right: 20px">
        <span>标准：</span>
        <el-select
          v-model="standardId"
          @change="getClause"
          placeholder="请选择"
        >
          <el-option
            v-for="item in standardList"
            :key="item.value"
            :label="item.standardName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="text">
        <span>公司：</span>
        <el-select v-model="compId" @change="changComany" placeholder="请选择">
          <el-option
            v-for="item in companyUserList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <el-table
      border
      :data="clauseList"
      @cell-dblclick="showIndex1"
      style="width: 100%"
      :height="elementHeight"
      :header-cell-style="{
        'background-color': '#EDF0F7',
        'border-bottom': '1px solid #D3D3D3',
        'border-right': '1px solid #D3D3D3',
      }"
      :stripe="true"
    >
      <el-table-column prop="standerItem" label="" width="150">
        <el-table-column
          prop="standerItem"
          label="题库"
          width="150"
        ></el-table-column
      ></el-table-column>
      <el-table-column prop="" label="部门">
        <el-table-column
          :resizable="false"
          v-for="(item, index) in tempData"
          :key="index"
          :prop="item.prop"
          :label="item.deptmentName"
        ></el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { get, post, deleteMethod } from "../../api/http";
export default {
  data() {
    return {
      elementHeight: 0,
      companyUserList: [],
      compId: 0,
      standardId: "",
      tempData: [],
      clauseList: [],
      standardList: [],
      checkedList: [],
      tableData: [],
    };
  },
  mounted() {
    this.companyUserList = this.$store.state.companyUserList;
    this.compId = this.$store.state.companyUserList[0].id;
    this.getElementHeight();
    this.getDeptList();
    this.getStandard();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 70 + 10);
      });
    },

    getStandard() {
      get("/api/StandardInfo/GetList").then((resp) => {
        if (resp.code == 200) {
          this.standardList = resp.data;
          this.standardId = resp.data[0].id;
          this.getClause();
        }
      });
    },
    changComany() {
      this.getDeptList(true);
      this.getTableData();
    },
    getDeptList(reset) {
      this.tempData = [];
      get("/api/Department/GetNoTree?CompId=" + this.compId).then((resp) => {
        if (resp.code == 200) {
          resp.data.map((item, index) => {
            this.tempData.push({
              deptmentName: item.deptmentName,
              prop: "prop" + index,
              id: item.id,
            });
          });
          if (reset) {
            //调用部门时题库不再调用，需清空题库数据
            this.clauseList.map((i) => {
              this.tempData.map((e, index) => {
                i["prop" + index] = "";
              });
            });
          }
        }
      });
    },
    getClause() {
      this.clauseList = [];
      get("api/BsStanderpool/" + this.standardId).then((resp) => {
        if (resp.code == 200) {
          resp.data.map((i) => {
            this.tempData.map((e, index) => {
              i["prop" + index] = "";
            });
          });
          this.clauseList = resp.data;
          this.getTableData();
        }
      });
    },
    getTableData() {
      get(
        "/api/StanderpoolandDept/GetByCompId?compid=" +
          this.compId +
          "&standerId=" +
          this.standardId
      ).then((resp) => {
        if (resp.code == 200) {
          if (resp.data.length != 0) {
            resp.data.map((t) => {
              this.clauseList.map((e) => {
                this.tempData.map((r, index) => {
                  if (t.deptId == r.id && t.questionId == e.id) {
                    e["prop" + index] = "✔";
                  }
                });
              });
            });
            this.tableData = resp.data;
          }
        } else {
          this.$message.error(resp.message);
        }
      });
    },
    showIndex1(row, column) {
      console.log("555", row, column);
      var rowIndex = this.clauseList.findIndex((p) => p.id == row.id);
      const columnIndex = column.id.split("_")[column.id.split("_").length - 1];
      if (columnIndex != 1 && columnIndex != 2) {
        //columnIndex值为1或2时则是表格第一列
        if (this.clauseList[rowIndex][column.property] == "") {
          this.clauseList[rowIndex][column.property] = "✔";
          var postItem = {};
          this.tempData.map((item) => {
            if (item.prop == column.property) {
              postItem = {
                id: 0,
                standerId: row.standerid,
                deptId: item.id,
                tenandId: JSON.parse(sessionStorage.getItem("userInfo"))
                  .tenantId,
                saveTime: new Date().toISOString(),
                saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id,
                questionId: row.id,
              };
            }
          });
          this.reqPostItem(postItem);
        } else {
          this.tableData.map((i) => {
            this.tempData.map((e) => {
              if (
                i.deptId == e.id &&
                row.id == i.questionId &&
                e.prop == column.property
              ) {
                console.log(666, i);
                this.reqDeleteItem(i.id);
              }
            });
          });
          this.clauseList[rowIndex][column.property] = "";
        }
      }
    },
    reqPostItem(item) {
      post("/api/StanderpoolandDept", item)
        .then((resp) => {
          if (resp.code == 200) {
            this.getTableData();
          } else {
            this.$message.errror(resp.$message);
          }
        })
        .catch(() => {
          this.$message.error("系统异常，请稍后再试");
        });
    },
    reqDeleteItem(id) {
      deleteMethod("/api/StanderpoolandDept?id=" + id)
        .then((resp) => {
          if (resp.code == 200) {
            this.getTableData();
          } else {
            this.$message.errror(resp.$message);
          }
        })
        .catch(() => {
          this.$message.error("删除失败");
        });
    },
  },
};
</script>

<style>
</style>