<template>
  <div>
    <div>
      <div class="_SelectHeader">
        <div>
          <div class="_Select">
            <span>公司：</span>
            <el-select v-model="value1" clearable placeholder="请选择" @change="compyInp">
              <el-option
                v-for="item in compyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>部门：</span>
            <el-select v-model="value2" clearable placeholder="请选择">
              <el-option
                v-for="item in deptList"
                :key="item.id"
                :label="item.deptmentName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <el-button icon="el-icon-search" @click="searchList"></el-button>
        </div>
        <div>
          <div style="margin-left: 10px">
            <el-tooltip class="item" effect="dark" content="导入文件格式为xlsx" placement="top">
              <el-button class="_ImgBtn" type="primary" style="margin: 0" @click="uploadFile">
                <img style="margin: 0" src="../../assets/images/import2.png" />
              </el-button>
            </el-tooltip>
          </div>
          <div style="margin-left: 10px">
            <el-button class="_ImgBtn" type="primary" style="margin: 0" @click="exportExcel">
              <img src="../../assets/images/import1.png" style="margin: 0" />
            </el-button>
          </div>
          <el-button type="primary" class="_ImgBtn" @click="operation('新增')">
            <img src="../../assets/images/add2.png" />
            新增岗位职能
          </el-button>
          <el-button
            type="warning"
            v-if="this.tableRowIndex != null"
            icon="el-icon-edit"
            @click="operation('修改')"
          >修改</el-button>
          <el-button
            type="danger"
            v-if="tableList.length != 0"
            icon="el-icon-delete"
            @click="deleteItem"
          >批量删除</el-button>
        </div>
      </div>
      <input
        type="file"
        @change="importFile(this)"
        id="imFile"
        ref="fileBtn"
        style="display: none"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <div>
        <el-table
          :data="isShow1 ? tableEmptyList : tableList"
          :height="elementHeight"
          style="width: 100%"
          border
          id="table"
          ref="multipleTable"
          @row-click="rowClick"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @selection-change="handleSelectionChange"
          :span-method="objectSpanMethod"
        >
          <el-table-column type="selection" width="60" v-if="isShow"></el-table-column>
          <el-table-column prop="compName" label="公司" v-if="!isShow"></el-table-column>
          <el-table-column prop="deptName" label="部门" v-if="!isShow"></el-table-column>
          <el-table-column prop="postName" label="岗位"></el-table-column>
          <el-table-column prop="functionName" label="职能"></el-table-column>
          <el-table-column prop="fileName" label="文件名称">
          </el-table-column>
          <el-table-column prop="fileNo" label="文件编号"></el-table-column>
          <el-table-column prop="isUpFile" label="上级公司文件">
            <template slot-scope="scope">
              <span>{{ scope.row.isUpFile == 1 ? "√" : "" }}</span>
            </template>
          </el-table-column>
        </el-table>

        <el-table
          :data="tableList"
          height="0"
          id="exportTable"
        >
          <el-table-column prop="compName" label="公司"></el-table-column>
          <el-table-column prop="deptName" label="部门"></el-table-column>
          <el-table-column prop="postName" label="岗位"></el-table-column>
          <el-table-column prop="functionName" label="职能"></el-table-column>
          <el-table-column prop="fileName" label="文件名称">
          </el-table-column>
          <el-table-column prop="fileNo" label="文件编号"></el-table-column>
          <el-table-column prop="isUpFile" label="上级公司文件">
            <template slot-scope="scope">
              <span>{{ scope.row.isUpFile == 1 ? "√" : "" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <Dialog
      :title="title"
      @handleClose="handleClose"
      width="45%"
      top="15vh"
      :showTowText="true"
      @handleReqData="handleReqData"
      :showDialog="showDialog"
    >
      <div class="dialog">
        <div>
          <div class="_Select">
            <span>公司：</span>
            <el-select
              v-model="compyValue"
              clearable
              placeholder="请选择"
              @change="compyDialogInp"
              :disabled="title == '修改'"
            >
              <el-option
                v-for="item in compyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>部门：</span>
            <el-select
              v-model="deptValue"
              clearable
              placeholder="请选择"
              :disabled="title == '修改'"
              @change="deptDialogInp"
            >
              <el-option
                v-for="item in deptDialogList"
                :key="item.id"
                :label="item.deptmentName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>岗位：</span>
            <el-select v-model="postValue" clearable placeholder="请选择" @change="postDialogInp">
              <el-option
                v-for="item in postDialogList"
                :key="item.id"
                :label="item.postName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>职能：</span>
            <el-select v-model="funValue" clearable placeholder="请选择">
              <el-option
                v-for="item in funDialogList"
                :key="item.functionId"
                :label="item.functionName"
                :value="item.functionId"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div style="width: 300px">
          <div class="_Inp">
            <span style="text-align: start">文件编号：</span>
            <el-input placeholder="文件编号" v-model="fileNo"></el-input>
          </div>
          <div class="_Inp">
            <span style="text-align: start">文件名称：</span>
            <el-input placeholder="文件名称" v-model="fileName"></el-input>
          </div>
          <div style="display: flex; align-items: center; margin-top: 15px">
            <span>是否勾选上级公司文件：</span>
            <el-checkbox v-model="rowChecked" @change="changRowChecked"></el-checkbox>
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog
      title="查看错误"
      :showDialog="showErrorDialog"
      width="80%"
      showTowText="true"
      @handleClose="handleClose2"
      @handleReqData="handleClose2"
      top="1vh"
      class="error-dialog"
    >
      <el-table :data="tableList3" height="545" style="width: 100%" border>
        <el-table-column prop="fileNo" label="文件名" width="320"></el-table-column>
        <el-table-column prop="saveTime" label="导入时间" width="150">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime.split("T")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="errorLog" label="错误信息" show-overflow-tooltip>
          <template slot-scope="scope">
            <span @click="clickText(scope.row.errorLog)">
              {{
              scope.row.errorLog
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </Dialog>
    <Dialog
      title="错误信息"
      :showDialog="showErrorText"
      width="55%"
      showTowText="true"
      @handleClose="showErrorText = false"
      @handleReqData="showErrorText = false"
      top="5vh"
      class="error-dialog"
    >
      <div class="error-text">
        <span>{{ errorText }}</span>
      </div>
    </Dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import Dialog from "../../components/dialog/Dialog.vue";
import { get, post, put } from "../../api/http";
import { exportExcel } from "../../modules/ExportExcel";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      tableList: [],
      tableList2: [],
      elementHeight: 0,
      showDialog: false,
      compyList: this.$store.state.companyList,
      deptList: [],
      deptDialogList: [],
      postDialogList: [],
      funDialogList: [],
      value1: "",
      value2: "",
      compyValue: null,
      deptValue: null,
      postValue: null,
      funValue: null,
      fileNo: "",
      fileName: "",
      tableRowIndex: null,
      title: "",
      deleteList: [],
      rowItem: {},
      rowChecked: false,
      showErrorDialog: false,
      tableList3: [],
      errorText: "",
      showErrorText: false,
      isShow: true,
      isShow1: false,
      tableEmptyList: [],
    };
  },
  created() {
    this.value1 = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
    this.compyInp(this.value1);
    this.value2 = JSON.parse(sessionStorage.getItem("userInfo")).upDeptmentId;
    this.getTableList();
  },
  mounted() {
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
  },
  methods: {
    // 合并相同列的数据
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if ((columnIndex === 1 || columnIndex === 2) && this.isShow) {
        // 获取当前单元格的值
        const currentValue = row[column.property];
        // 获取上一行相同列的值
        const preRow = this.tableList[rowIndex - 1];
        const preValue = preRow ? preRow[column.property] : null;
        // 如果当前值和上一行的值相同，则将当前单元格隐藏
        if (currentValue === preValue) {
          return { rowspan: 0, colspan: 0 };
        } else {
          // 否则计算当前单元格应该跨越多少行
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.tableList.length; i++) {
            const nextRow = this.tableList[i];
            const nextValue = nextRow[column.property];
            if (nextValue === currentValue) {
              rowspan++;
            } else {
              break;
            }
          }
          return { rowspan, colspan: 1 };
        }
      }
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 104);
      });
    },
    handleClose() {
      this.showDialog = false;
    },
    rowClick(row) {
      this.tableRowIndex = row.index;
      this.rowItem = row;
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    // 搜索列表
    searchList() {
      if (!this.value2) {
        this.$message({
          type: "warning",
          message: "部门为空",
        });
        return;
      }
      this.getTableList();
    },
    compyInp(e) {
      this.value2 = "";
      if (e) {
        this.getDeptList(e);
      }
    },
    getTableList() {
      get(
        "/api/PostAndFile/GetList?CompId=" +
          this.value1 +
          "&DeptId=" +
          this.value2
      ).then((resp) => {
        if (resp.code == 200) {
          this.tableList = resp.data;
          this.tableList2 = JSON.parse(JSON.stringify(resp.data));
        }
      });
    },
    // 获取公司部门岗位职能
    getDeptList(e) {
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        if (this.showDialog) {
          this.deptDialogList = res.data;
          if (this.title == "修改") {
            this.getPostList(e, this.rowItem.deptId);
          }
        } else {
          this.deptList = res.data;
        }
      });
    },
    getPostList(c, d) {
      get("/api/Post?CompId=" + c + "&DeptId=" + d).then((resp) => {
        if (resp.code == 200) {
          this.postDialogList = resp.data;
          if (this.title == "修改") {
            this.getFunList(this.rowItem.postId);
          }
        }
      });
    },
    getFunList(p) {
      get("/api/PostAndFunction/GetPostAndFunction?PostId=" + p).then(
        (resp) => {
          if (resp.code == 200) {
            this.funDialogList = resp.data;
          }
        }
      );
    },
    // 弹窗下拉框
    compyDialogInp(e) {
      this.deptValue = "";
      this.postValue = "";
      this.postDialogList = [];
      this.funValue = "";
      this.funDialogList = [];
      this.getDeptList(e);
    },
    deptDialogInp(c) {
      this.postValue = "";
      this.funValue = "";
      this.funDialogList = [];
      this.getPostList(this.compyValue, c);
    },
    postDialogInp(p) {
      this.funValue = "";
      this.getFunList(p);
    },
    changRowChecked(e) {
      this.rowChecked = e;
    },
    // 导入excel
    uploadFile: function () {
      this.$confirm("是否需要标准格式文件?", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // this.tableList = [];
          this.isShow1 = true;
          this.isShow = false;
          if (!this.isShow) {
            this.$nextTick(() => {
              exportExcel("#table", "标准格式文件.xlsx");
              this.isShow1 = false;
              this.isShow = true;
            });
          }
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      this.tableList = [];
      var list = [];
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        var stopExecution = false;
        excelData.map((item, index) => {
          if (stopExecution) return;
          if (
            !Object.prototype.hasOwnProperty.call(item, "公司") &&
            index == 0
          ) {
            $this.$message.error("导入文件不符合");
            stopExecution = true;
            return;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "公司")) {
            item.公司 = excelData[index - 1].公司;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "部门")) {
            item.部门 = excelData[index - 1].部门;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "岗位")) {
            item.岗位 = excelData[index - 1].岗位;
          }
          if (!Object.prototype.hasOwnProperty.call(item, "职能")) {
            item.职能 = excelData[index - 1].职能;
          }
          var data = {
            CompName: item.公司,
            DeptName: item.部门,
            PostName: item.岗位,
            FunctionName: item.职能,
            FileNo: item.文件编号,
            FileName: item.文件名称,
            IsUpFile: item.上级公司文件 ? 1 : 0,
          };
          var data2 = {
            compName: item.公司,
            deptName: item.部门,
            postName: item.岗位,
            functionName: item.职能,
            fileNo: item.文件编号,
            fileName: item.文件名称,
            isUpFile: item.上级公司文件 ? 1 : 0,
          };
          list.push(data);
          $this.tableList.push(data2);
          $this.handleAdd(list, obj.files[0].name.split(".")[0]);
        });
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    handleAdd(list, exalName) {
      console.log(exalName);
      post("/api/PostAndFile/BatchAdd?ExcelName=" + exalName, {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.showDialog ? "新增成功" : "导入成功",
            });
            this.$refs.fileBtn.value = null;
            // 导入后显示
            let c = this.compyList.filter((c) => {
              return c.companyName == list[0].CompName;
            });
            get("/api/Department/GetNoTree?CompId=" + c[0].id).then((resp) => {
              this.deptList = resp.data;
              let d = resp.data.filter((d) => {
                return d.deptmentName == list[0].DeptName;
              });
              this.value1 = c[0].id;
              this.value2 = d[0].id;
              this.getTableList();
              if (this.showDialog) this.showDialog = false;
            });
          } else {
            this.tableList = [];
            // this.showError(resp.message);
            this.$refs.fileBtn.value = null;
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.tableList = [];
          this.$message.error(this.showDialog ? "新增失败" : "导入失败");
        });
    },
    // 导出
    exportExcel() {
      exportExcel("#exportTable", "岗位文件表.xlsx");
    },
    // 修改、删除操作
    operation(title) {
      this.showDialog = true;
      this.title = title;
      if (title == "修改") {
        this.compyValue = this.rowItem.compId;
        this.deptValue = this.rowItem.deptId;
        this.postValue = this.rowItem.postId;
        this.funValue = this.rowItem.functionId;
        this.getDeptList(this.rowItem.compId);
        this.fileNo = this.rowItem.fileNo;
        this.fileName = this.rowItem.fileName;
        this.rowChecked = this.rowItem.isUpFile == 1 ? true : false;
      } else {
        this.compyValue = null;
        this.deptValue = null;
        this.postValue = null;
        this.funValue = null;
        this.fileNo = "";
        this.fileName = "";
        this.rowChecked = false;
      }
    },

    handleReqData() {
      if (
        !this.funValue ||
        !this.compyValue ||
        !this.deptValue ||
        !this.postValue ||
        !this.fileNo ||
        !this.fileName
      ) {
        this.$message({
          type: "warning",
          message: "所有选项不能为空",
        });
        return;
      }
      let f = this.funDialogList.filter((f) => {
        return f.functionId == this.funValue;
      });
      if (this.title == "新增") {
        let c = this.compyList.filter((c) => {
          return c.id == this.compyValue;
        });
        let d = this.deptDialogList.filter((d) => {
          return d.id == this.deptValue;
        });
        let p = this.postDialogList.filter((p) => {
          return p.id == this.postValue;
        });

        let list = [
          {
            CompName: c[0].companyName,
            DeptName: d[0].deptmentName,
            PostName: p[0].postName,
            FunctionName: f[0].functionName,
            FileNo: this.fileNo,
            FileName: this.fileName,
            IsUpFile: this.rowChecked ? 1 : 0,
          },
        ];
        this.handleAdd(list);
        return;
      }
      var data = {
        id: this.rowItem.id,
        compId: this.compyValue,
        deptId: this.deptValue,
        postId: this.postValue,
        functionId: this.funValue,
        functionName: f[0].functionName,
        fileNo: this.fileNo,
        fileName: this.fileName,
        isUpFile: this.rowChecked ? 1 : 0,
      };
      put("/api/PostAndFile", data).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.showDialog = false;
          this.tableRowIndex = null;
          this.getTableList();
        }
      });
    },
    deleteItem() {
      console.log(this.deleteList);
      this.$confirm("您确认要删除当前所选中的列表？").then(() => {
        var data = [];
        this.deleteList.map((item) => {
          data.push({
            Id: item.id,
          });
        });
        post("/api/PostAndFile/BatchDelete", {
          data: JSON.stringify(data),
        })
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getTableList();
              this.tableRowIndex = null;
            } else {
              this.$message.error(resp.message);
            }
          })
          .catch(() => {
            this.$message.error("删除失败");
          });
      });
    },
    // 勾选
    handleSelectionChange(val) {
      this.deleteList = val;
      console.log(this.deleteList);
    },
    // 查看错误
    showError(msg) {
      this.$confirm(msg, {
        confirmButtonText: "查看错误",
        cancelButtonText: "关闭",
        type: "error",
      })
        .then(() => {
          get(
            "/api/PostErrorlog/GetByUserId?UserId=" +
              JSON.parse(sessionStorage.getItem("userInfo")).id
          ).then((resp) => {
            if (resp.code == 200) {
              this.tableList3 = resp.data;
            }
          });
          this.showErrorDialog = true;
        })
        .catch(() => {
          location.reload();
        });
    },
    handleClose2() {
      this.showErrorDialog = false;
      location.reload();
    },
    clickText(e) {
      this.errorText = e;
      this.showErrorText = true;
    },
  },
};
</script>

<style lang="less" scoped>
#table {
  ::v-deep .el-table__row td {
    border-bottom: 1px solid #ebeef5 !important;
  }
}
._SelectHeader {
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
  }
}

.operation {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
}

.dialog {
  display: flex;

  ._Select {
    margin-top: 10px;
  }

  > div:nth-of-type(2) {
    margin-left: 50px;
  }
}

.error-text {
  width: 100%;
  height: 400px;
  overflow: scroll;
}
</style>
