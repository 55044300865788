<template>
  <div class="container">
    <el-form :inline="true" class="form">
      <el-form-item label="编号:">
        <el-select v-model="serial" placeholder="请选择" clearable>
          <el-option
            v-for="item in serialList"
            :key="item.id"
            :value="item.id"
            :label="item.serialName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search"></el-button>
      </el-form-item>
      <el-form-item style="position: absolute;right:0">
        <el-button class="el-icon-document-add" type="primary" @click="handleAdd">新增问题</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="stage" label="阶段"></el-table-column>
        <el-table-column prop="no" label="NO"></el-table-column>
        <el-table-column prop="description" label="问题描述">
          <template slot-scope="scope">
            <div style="text-align: left">{{ scope.row.description }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="评分标准"></el-table-column>
        <el-table-column prop="recordType" label="记录类型"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      ></el-pagination>
    </div>
    <!-- 新增问题弹框 -->
    <el-dialog title="新增问题" :visible.sync="dialogVisible" width="478px">
      <el-form :model="form" label-width="auto" class="addForm">
        <el-form-item label="阶段">
          <el-select v-model="form.stage" placeholder="请选择" clearable style="width:100%">
            <el-option
              v-for="item in serialList"
              :key="item.id"
              :value="item.id"
              :label="item.serialName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="编号">
          <el-input v-model="form.serial" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="问题描述">
          <el-input v-model="form.description" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="评分标准">
          <el-input v-model="form.score" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="记录类型">
          <el-input v-model="form.recordType" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serial: "",
      serialList: [],
      elementHeight: 0,
      tableData: [],
      multipleSelection: [],
      // 分页
      start: 0,
      end: 10,
      currentPage: 1,
      pageSize: 10,
      dialogVisible: false,
      form: {},
    };
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 56 + 48);
      });
    },
    // 新增供应商
    handleAdd() {
      this.dialogVisible = true;
    },
    // 删除按钮
    handleDelete(row) {
      console.log(row);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.start = (this.currentPage - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  .el-form-item {
    margin-bottom: 16px !important;
  }
  ::v-deep .el-form-item__label {
    font-weight: bold;
    color: #3d3d3d;
    padding-right: 6px;
  }
}
._Pagination {
  text-align: end;
  padding: 10px 20px;
  background-color: #ffffff;
}
.addForm {
  .el-form-item {
    margin-bottom: 10px !important;
    margin-right: 24px;
  }
  ::v-deep .el-input__inner {
    height: 40px !important;
  }
}
</style>