<template>
  <div>
    <div>
      <div class="_SelectHeader">
        <div class="_Select">
          <span>公司：</span>
          <el-select
            v-model="value1"
            clearable
            placeholder="请选择"
            @clear="handleCompy"
            @change="togetArea"
          >
            <el-option
              v-for="item in compyList"
              :key="item.value"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span>部门：</span>
          <el-select
            v-model="value2"
            clearable
            @clear="handleDept"
            @change="changDept"
            placeholder="请选择"
          >
            <el-option
              v-for="item in option2"
              :key="item.value"
              :label="item.deptmentName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span>姓名：</span>
          <el-input v-model="userName"></el-input>
        </div>
        <div class="_Select">
          <span>编号：</span>
          <el-input v-model="userNo"></el-input>
        </div>
        <el-button icon="el-icon-search" @click="handleSearch"></el-button>
        <el-button
          class="addbtn"
          type="primary"
          icon="el-icon-document-add"
          @click="addJobFun"
          >新增</el-button
        >
      </div>
      <div class="_TableBox">
        <el-table
          :data="personlList"
          style="width: 100%"
          :height="elementHeight"
          :stripe="true"
        >
          <el-table-column type="selection" width="80"></el-table-column>
          <el-table-column prop="userName" label="姓名"></el-table-column>
          <el-table-column
            prop="CpyName"
            label="所属公司"
            width="180"
          ></el-table-column>
          <el-table-column prop="deptName" label="所属部门"></el-table-column>
          <el-table-column
            prop="postName"
            label="所属岗位"
            width="100"
          ></el-table-column>
          <el-table-column prop="saveTime" label="保存时间">
            <template slot-scope="scope">
              <span>{{ scope.row.saveTime.split("T")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="saveUserName" label="保存人"></el-table-column>
          <el-table-column prop="roleName" label="绑定角色"></el-table-column>
          <el-table-column prop="address" label="操作" width="100">
            <template slot-scope="scope">
              <i
                id="i"
                class="el-icon-delete"
                @click="deleteJobFun(scope.row)"
              ></i>
              <i id="i" class="el-icon-edit" @click="editItem(scope.row)"></i>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count"
          class="_Pagination"
        ></el-pagination>
      </div>
    </div>
    <Dialog
      title="添加人员"
      :showTowText="false"
      width="40%"
      top="25vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
    >
      <div style="display: flex">
        <div>
          <div class="_Select">
            <span>人员姓名</span>
            <el-input v-model="user.userName"></el-input>
          </div>
          <div class="_Select">
            <span>人员编号</span>
            <el-input v-model="user.userNo"></el-input>
          </div>
          <div class="_Select">
            <span>密码</span>
            <el-input v-model="user.password"></el-input>
          </div>
          <div class="_Select">
            <span style="width: 84px">绑定角色</span>
            <el-select v-model="roleValue" placeholder="请选择" multiple collapse-tags>
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.roleName"
                :value="item.roleNo"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div class="_Select">
            <span>所属公司:</span>
            <el-select
              v-model="user.upCompanyId"
              clearable
              placeholder="请选择"
              @change="togetArea"
            >
              <el-option
                v-for="item in compyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>所属部门</span>
            <el-select
              v-model="user.upDeptmentId"
              clearable
              @change="togetPost2"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option22"
                :key="item.id"
                :label="item.deptmentName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>所属岗位</span>
            <el-select v-model="user.post" placeholder="请选择">
              <el-option
                v-for="item in option3"
                :key="item.id"
                :label="item.postName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </Dialog>
    <Dialog
      title="修改人员"
      :showTowText="true"
      width="40%"
      top="25vh"
      :showDialog="showDialog1"
      class="dialog"
      @handleReqData="handleReqData1"
      @handleClose="handleClose"
    >
      <div style="display: flex">
        <div>
          <div class="_Select">
            <span>人员姓名</span>
            <el-input v-model="Euser.userName"></el-input>
          </div>
          <div class="_Select">
            <span>人员编号</span>
            <el-input v-model="Euser.userNo"></el-input>
          </div>
          <!-- <div class="_Select">
            <span>密码</span>
            <el-input v-model="Euser.password"></el-input>
          </div> -->
          <div class="_Select">
            <span style="width: 84px">绑定角色</span>
            <el-select
              v-model="roleValue"
              @change="changRoleValue"
              placeholder="请选择"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.roleName"
                :value="item.roleNo"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div>
          <div class="_Select">
            <span>所属公司:</span>
            <el-select
              v-model="Euser.upCompanyId"
              clearable
              placeholder="请选择"
              @change="togetArea"
            >
              <el-option
                v-for="item in compyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>所属部门</span>
            <el-select
              v-model="Euser.upDeptmentId"
              clearable
              @change="togetPost"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option22"
                :key="item.id"
                :label="item.deptmentName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>所属岗位</span>
            <el-select v-model="Euser.post" placeholder="请选择">
              <el-option
                v-for="item in option3"
                :key="item.id"
                :label="item.postName"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { deleteMethod, get, post, put } from "../../api/http";
import Dialog from "../../components/dialog/Dialog.vue";
export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      isSerach: false,
      showDialog: false,
      personlList: [],
      showDialog1: false,
      // 下拉框
      compyList: [],
      deptList: [],
      jobList: [],
      functionList: [],
      userName: "",
      userNo: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      options: [],
      option2: [],
      option3: [],
      option22: [],
      user: {
        id: 0,
        userNo: "",
        userName: "",
        upCompanyId: null,
        upDeptmentId: null,
        post: "",
        password: "",
        isDelete: 0,
        tenantId: 0,
        saveTime: new Date().toISOString(),
        saveUserId: "",
        roleName: "",
        roleNo: "",
        phone: "",
        email: "",
        signature: "",
        sex: 0,
      },
      Euser: {
        id: 0,
        userNo: "",
        userName: "",
        upCompanyId: null,
        upDeptmentId: null,
        post: "",
        password: "",
        isDelete: 0,
        tenantId: 0,
        saveTime: new Date().toISOString(),
        saveUserId: "",
        roleName: "",
        roleNo: "",
        phone: "",
        email: "",
        signature: "",
        sex: 0,
      },
      Area: "",
      // 分页
      currentPage: 1,
      pageSize: 10,
      count: 0,
      // 角色
      roleList: [],
      roleValue: "",
    };
  },
  created() {
    this.user.tenantId = this.$store.state.tenantId;
    this.user.saveUserId = this.$store.state.userInfo.id + "";
    this.Euser.tenantId = this.$store.state.tenantId;
    this.Euser.saveUserId = this.$store.state.userInfo.id + "";
    this.compyList = this.$store.state.companyList;
    this.getDeptAll();
    // this.getFunList();
    this.getElementHeight();
  },
  mounted() {
    this.getPersonList();
    this.getRole();
  },
  filters: {
    CpyName: (e) => {
      this.compyList.forEach((i) => {
        if (i.id === Number(e)) {
          return i.companyName;
        }
      });
    },
  },
  methods: {
    changRoleValue() {
      console.log(this.roleValue);
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 70 + 60); //70是div底部距离底部的高度
      });
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.isSerach ? this.searchList() : this.getPersonList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.isSerach ? this.searchList() : this.getPersonList();
    },
    handleClose() {
      this.showDialog = false;
      this.showDialog1 = false;
    },
    handleReqData() {
      this.addJobFunItem();
    },
    getRole() {
      get("/api/Role").then((resp) => {
        if (resp.code == 200) {
          this.roleList = resp.data;
        }
      });
    },
    getDeptAll() {
      get("/api/Department/GetAllDept").then((resp) => {
        if (resp.code == 200) {
          this.deptList = resp.data;
        }
      });
    },
    // getFunList() {
    //   get("/api/Post/GetList").then((resp) => {
    //     if (resp.code == 200) {
    //       this.functionList = resp.data;
    //     }
    //   });
    // },
    // 搜索人员信息
    handleCompy() {
      this.currentPage = 1;
      this.getPersonList();
    },
    handleDept() {
      this.currentPage = 1;
      if (this.value1) {
        this.searchList();
      } else {
        this.getPersonList();
      }
    },
    changDept(e) {
      this.value2 = e;
    },
    handleSearch() {
      this.isSerach = true;
      this.currentPage = 1;
      this.searchList();
    },
    searchList() {
      get(
        "/api/User/GetByConditionPage?CompId=" +
          this.value1 +
          "&DeptId=" +
          this.value2 +
          "&UserNo=" +
          this.userNo +
          "&UserName=" +
          this.userName +
          "&limit=" +
          this.pageSize +
          "&page=" +
          this.currentPage
      ).then((resp) => {
        if (resp.code == 200) {
          resp.data.forEach((e) => {
            this.compyList.forEach((i) => {
              if (i.id === e.upCompanyId) {
                e.CpyName = i.companyName;
              }
            });
            this.deptList.forEach((l) => {
              if (l.id === e.upDeptmentId) {
                e.deptName = l.deptmentName;
              }
            });
            this.functionList.forEach((k) => {
              if (k.id === Number(e.post)) {
                e.postName = k.postName;
              }
            });
          });
          this.count = resp.count;
          this.personlList = resp.data;
        }
      });
    },
    async getPersonList() {
      this.isSerach = false;
      this.value2 = "";
      this.userName = "";
      this.userNo = "";
      await get("/api/Post/GetList").then((resp) => {
        if (resp.code == 200) {
          this.functionList = resp.data;
        }
      });
      await get(
        "/api/User/GetPage?limit=" + this.pageSize + "&page=" + this.currentPage
      ).then((resp) => {
        if (resp.code == 200) {
          resp.data.forEach((e) => {
            this.compyList.forEach((i) => {
              if (i.id === e.upCompanyId) {
                e.CpyName = i.companyName;
              }
            });
            this.deptList.forEach((l) => {
              if (l.id === e.upDeptmentId) {
                e.deptName = l.deptmentName;
              }
            });
            this.functionList.forEach((k) => {
              if (k.id === Number(e.post)) {
                e.postName = k.postName;
              }
            });
          });
          this.count = resp.count;
          this.personlList = resp.data;
        }
      });
    },
    // 关联角色
    associatedRole(item) {
      var list = [];
      this.roleList.map((e) => {
        this.roleValue.map((i) => {
          if (i == e.roleNo) {
            list.push({
              userNo: item.userNo,
              relationRoleNo: e.roleNo,
              relationRoleName: e.roleName,
            });
          }
        });
      });
      post("/api/UserRole?UserNo=" + item.userNo + "", {
        data: JSON.stringify(list),
      })
        .then(() => {
          // if (resp.code != 200) {
          //   this.$message.error(resp.message);
          //   return;
          // }
          this.getPersonList();
          // this.$message({
          //   type: "success",
          //   message: resp.message,
          // });
        })
        // .catch(() => {
        //   this.$message.error("角色关联失败");
        // });
    },
    // 添加职能
    handleSelect(val) {
      this.value4 = val;
    },
    togetPost(e) {
      //修改
      if (!e) return;
      this.Euser.post = "";
      get(
        "/api/Post?CompId=" +
          this.Euser.upCompanyId +
          "&DeptId=" +
          this.Euser.upDeptmentId
      ).then((res) => {
        this.option3 = res.data;
      });
    },
    togetPost2(e) {
      //添加
      if (!e) return;
      get(
        "/api/Post?CompId=" +
          this.user.upCompanyId +
          "&DeptId=" +
          this.user.upDeptmentId
      ).then((res) => {
        this.option3 = res.data;
      });
    },
    togetArea(e) {
      if (!e) return;
      this.Euser.upDeptmentId = '';
      this.Euser.post = '';
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        if (this.showDialog1 || this.showDialog) {
          this.option22 = res.data;
        } else {
          this.value2 = "";
          this.value1 = e;
          this.option2 = res.data;
        }
      });
    },
    editItem(e) {
      console.log(e,'e');
      get("/api/Department/GetNoTree?CompId=" + e.upCompanyId).then((res) => {
        this.option22 = res.data;
        this.Euser.upDeptmentId = e.upDeptmentId != 0?e.upDeptmentId * 1:'';
      });
      get(
        "/api/Post?CompId=" + e.upCompanyId + "&DeptId=" + e.upDeptmentId
      ).then((res) => {
        this.option3 = res.data;
        this.Euser.post = e.post != 0?e.post * 1:'';
      });
      this.Euser = e;
      this.Euser.upCompanyId = e.upCompanyId == 0?'':e.upCompanyId;
      this.showDialog1 = true;
      this.roleValue = e.roleNo ? e.roleNo.split(",") : "";
    },
    handleReqData1() {
      this.Euser.post = this.Euser.post + "";
      this.Euser.upDeptmentId = Number(this.Euser.upDeptmentId);
      put("/api/User", this.Euser).then((res) => {
        if(res.code == 200){
          if (this.roleValue) {
            this.associatedRole(this.Euser);
          } else {
            this.getPersonList();
            this.$message({
              type: "success",
              message: res.message,
            });
          }
        }else{
          this.$message.error(res.message);
        }
        this.showDialog1 = false;
      });
    },
    addJobFun() {
      this.user.id = 0;
      this.user.userNo = "";
      this.user.userName = "";
      this.user.upCompanyId = null;
      this.user.upDeptmentId = null;
      this.user.post = "";
      this.user.password = "";
      this.showDialog = true;
      this.value4 = [];
      this.roleValue = "";
    },
    addJobFunItem() {
      this.user.post = this.user.post + "";
      this.user.upDeptmentId = Number(this.user.upDeptmentId);
      post("/api/User", this.user).then((res) => {
        if (res.code == 200) {
          if (this.roleValue) {
            this.associatedRole(this.user);
          } else {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.getPersonList();
          }
          this.showDialog = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    deleteJobFun(row) {
      this.$confirm("您确认要删除" + row.userName + "这人员？")
        .then(() => {
          deleteMethod("/api/User?Id=" + row.id)
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
            })
            .then(() => {
              this.getPersonList();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.dialog {
  ._Select {
    margin-top: 10px;
    width: 90% !important;
    span {
      padding-right: 10px;
    }
    /deep/ .el-select__tags {
      flex-wrap: nowrap !important;
      overflow: hidden;
    }
  }
}
.el-icon-delete {
  color: red;
}
#i {
  border-radius: 50%;
  border: 1px solid silver;
  padding: 5px;
  margin: 0 5px;
}

#i:nth-of-type(1) {
  background-color: #fef9f9;
  color: red;
  border: 1px solid #d3b2b2;
}
#i:nth-of-type(2) {
  background-color: #eff3fa;
  color: #5a8bff;
  border: 1px solid #b2c7d3;
}
.addbtn {
  position: absolute;
  right: 20px;
}
</style>
