<template>
  <div>
    <div>
      <div class="_SelectHeader">
        <div>
          <span>常规标准：</span>
          <el-select clearable v-model="stander" placeholder="请选择文件标准">
            <el-option
              v-for="item in standardList"
              :key="item.value"
              :label="item.standardName"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-button icon="el-icon-search" @click="getTableList"></el-button>
        </div>
        <div>
          <el-button type="primary" @click="opneDialog(true)" class="_ImgBtn">✚ 新增</el-button>
          <el-button
            icon="el-icon-edit"
            type="warning"
            @click="opneDialog(false)"
            v-if="this.tableRowIndex != null"
          >修改</el-button>
          <el-button
            icon="el-icon-edit"
            type="danger"
            @click="deleteQuestion"
            v-if="this.tableRowIndex != null"
          >删除</el-button>
          <div>
            <el-tooltip class="item" effect="dark" content="导入文件格式为xlsx" placement="top">
              <el-button type="primary" @click="uploadFile" class="_ImgBtn">
                <img src="../../assets/images/import2.png" />
                导入题库
              </el-button>
            </el-tooltip>
            <input
              type="file"
              @change="importFile(this)"
              id="imFile"
              ref="fileBtn"
              style="display: none"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div>
      </div>
      <div>
        <el-table
          :data="tableList"
          :height="elementHeight"
          style="width: 100%"
          border
          id="table"
          v-loading="loading"
          @row-click="rowClick"
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="standerNo" label="标准简称" width="100" v-if="!isShow"></el-table-column>
          <el-table-column prop="stnderName" label="标准名称" v-if="!isShow">
            <template slot-scope="scope">
              <span>{{ scope.row.stnderName ? scope.row.stnderName : scope.row.standerName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="standerItem" label="条款/范围"></el-table-column>
          <el-table-column prop="content" label="内容" width="350">
            <template slot-scope="scope">
              <div style="text-align:left">{{ scope.row.content }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="auditModule" label="参考方式"></el-table-column>
          <el-table-column prop="answer" label="标准要求"></el-table-column>
          <el-table-column prop="version" label="版本号" width="100"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
      </div>
    </div>
    <Dialog
      :title="(isAdd ? '新增' : '修改') + '题库'"
      :showDialog="showDialog"
      width="30%"
      showTowText="true"
      @handleClose="handleClose"
      @handleReqData="handleReqData"
      top="5vh"
      class="standrdDialog"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="文件标准：">
          <el-select v-model="standerid" placeholder="请选择文件标准">
            <el-option
              v-for="item in standardList"
              :key="item.value"
              :label="item.standardName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="条款/范围：">
          <el-input placeholder="请输入条款/范围" v-model="standerItem"></el-input>
        </el-form-item>
        <el-form-item label="检查内容：">
          <el-input type="textarea" placeholder="请输入检查内容" v-model="content"></el-input>
        </el-form-item>
        <el-form-item label="参考方式：">
          <el-input type="textarea" placeholder="请输入参考方式" v-model="auditModule"></el-input>
        </el-form-item>
        <el-form-item label="标准答案：">
          <el-input type="textarea" placeholder="请输入标准答案" v-model="answer"></el-input>
        </el-form-item>
        <el-form-item label="版本号：">
          <el-input placeholder="请输入版本号" v-model="version"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" placeholder="请输入备注" v-model="remark"></el-input>
        </el-form-item>
      </el-form>
    </Dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import { get, post, put, deleteMethod } from "../../api/http";
import { exportExcel } from "../../modules/ExportExcel";
import Dialog from "../../components/dialog/Dialog.vue";

export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      stander: null,
      loading: false,
      tableList: [],
      standardList: [],
      rowItem: {},
      isAdd: true,
      showDialog: false,
      standerid: 0,
      standerItem: "",
      content: "",
      auditModule: "",
      answer: "",
      version: "",
      remark: "",
      tableRowIndex: null,
      isShow: true,
    };
  },

  mounted() {
    this.imFile = document.getElementById("imFile");
    this.getElementHeight();
    // this.getStandard();
    // this.getTableList();
    this.loadData();
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById("table").offsetTop + 104);
      });
    },

    async loadData() {
      await this.getStandard();
      await this.getTableList();
    },

    async getStandard() {
      try {
        const resp = await get("/api/StandardInfo/GetList");
        if (resp.code == 200) {
          this.standardList = resp.data;
          this.stander = this.standardList[0].id
            ? this.standardList[0].id
            : '';
        } else {
          this.$message.error(resp.message);
        }
      } catch (error) {
        this.$message.error("获取标准信息失败");
      }
    },

    async getTableList() {
      try {
        const resp = await get(
          "/api/BsStanderpool" + (this.stander ? "/" + this.stander : "")
        );
        if (resp.code == 200) {
          this.tableList = resp.data;
        } else {
          this.$message.error(resp.message);
        }
      } catch (error) {
        this.$message.error("查询失败");
      }
    },
    // 导入excel
    uploadFile: function () {
      this.$confirm("是否下载题库导入标准格式", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.isShow = false;
          this.tableList = [];
          if (!this.isShow) {
            this.$nextTick(() => {
              exportExcel("#table", "标准格式文件.xlsx");
              this.isShow = true;
            });
          }
        })
        .catch((action) => {
          if (action === "cancel") {
            // 点击导入按钮
            this.imFile.click();
          }
        });
    },
    importFile: function () {
      let obj = this.imFile;
      if (!obj.files) {
        return;
      }
      this.tableList = [];
      var f = obj.files[0];
      var reader = new FileReader();
      let $this = this;
      reader.onload = function (e) {
        var data = e.target.result;
        if ($this.rABS) {
          $this.wb = XLSX.read(btoa(this.fixdata(data)), {
            // 手动转化
            type: "base64",
          });
        } else {
          $this.wb = XLSX.read(data, {
            type: "binary",
          });
        }
        var excelData = XLSX.utils.sheet_to_json(
          $this.wb.Sheets[$this.wb.SheetNames[0]]
        );
        var stopExecution = false;
        excelData.map((item, index) => {
          if (stopExecution) return;
          // 第一和第二行为标题的情况下不导入
          if (
            (index == 0 || index == 1) &&
            !Object.prototype.hasOwnProperty.call(item, "标准简称")
          )
            return (stopExecution = true);
          if (
            //从exal表第一行开始必须要有公司
            !Object.prototype.hasOwnProperty.call(item, "标准简称") &&
            index == 2
          ) {
            return (stopExecution = true);
          }
          // if (!Object.prototype.hasOwnProperty.call(item, '标准简称')) {
          //   item.标准简称 = excelData[index - 1].标准简称
          // }
          // if (!Object.prototype.hasOwnProperty.call(item, '标准名称')) {
          //   item.标准名称 = excelData[index - 1].标准名称
          // }
          // if (!Object.prototype.hasOwnProperty.call(item, '条款/范围')) {
          //   item.条款/范围 = excelData[index - 1].条款/范围
          // }
          // if (!Object.prototype.hasOwnProperty.call(item, '内容')) {
          //   item.内容 = excelData[index - 1].内容
          // }
          // if (!Object.prototype.hasOwnProperty.call(item, '参考方式')) {
          //   item.参考方式 = excelData[index - 1].参考方式
          // }
          // if (!Object.prototype.hasOwnProperty.call(item, '标准要求')) {
          //   item.标准要求 = excelData[index - 1].标准要求
          // }
          var data = {
            // id: 0,
            // standerid: 0,
            standerNo: item.标准简称 + "",
            standerName: item.标准名称,
            standerItem: item["条款/范围"] + "",
            content: item.内容,
            auditModule: item.参考方式 ? item.参考方式 : "",
            answer: item.标准要求 + "" ? item.标准要求 + "" : "",
            version: item.版本号 ? item.版本号 : "",
            remark: item.备注 ? item.备注 : "",
            saveTime: new Date().toISOString(),
            saverUserId: $this.$store.state.userInfo.id,
          };
          $this.tableList.push(data);
        });
        if ($this.tableList.length != 0) {
          $this.postBatchAdd($this.tableList);
        } else {
          console.log("格式不符合的数据=", excelData);
          $this.$message({
            type: "warning",
            message: "导入文件不符合",
          });
          this.$refs.fileBtn.value = null;
        }
      };
      if (this.rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    async postBatchAdd(list) {
      this.loading = true;
      await post("/api/BsStanderpool/importBatch", JSON.stringify(list))
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "导入成功",
            });
            this.loading = false;
            this.$refs.fileBtn.value = null;
          } else {
            this.$message.error(resp.message);
            this.loading = false;
            this.tableList = [];
            this.$refs.fileBtn.value = null;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message.error("系统异常,请稍后再试");
        });
    },
    rowClick(row) {
      this.tableRowIndex = row.index;
      this.rowItem = row;
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#409EFF",
        };
      }
    },
    opneDialog(isAdd) {
      this.showDialog = true;
      this.isAdd = isAdd;
      if (isAdd) {
        this.standerid = null;
        this.standerItem = "";
        this.content = "";
        this.auditModule = "";
        this.answer = "";
        this.version = "";
        this.remark = "";
      } else {
        var rowItem = this.rowItem;
        this.standerid = rowItem.standerid;
        this.standerItem = rowItem.standerItem;
        this.content = rowItem.content;
        this.auditModule = rowItem.auditModule;
        this.answer = rowItem.answer;
        this.version = rowItem.version;
        this.remark = rowItem.remark;
      }
    },
    handleClose() {
      this.showDialog = false;
    },
    handleReqData() {
      var http = this.isAdd ? post : put;
      http("/api/BsStanderpool", {
        id: this.isAdd ? 0 : this.rowItem.id,
        standerid: this.standerid,
        standerItem: this.standerItem,
        content: this.content,
        auditModule: this.auditModule,
        answer: this.answer,
        version: this.version,
        remark: this.remark,
        saveTime: new Date().toISOString(),
        saverUserId: this.$store.state.userInfo.id,
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "新增成功" : "修改成功",
            });
            this.getTableList();
            this.tableRowIndex = null;
            this.showDialog = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "新增失败" : "修改失败");
        });
    },
    deleteQuestion() {
      this.$confirm(
        "您确认要删除这个" + this.rowItem.fialName + "的题库？"
      ).then(() => {
        deleteMethod("/api/BsStanderpool?Id=" + this.rowItem.id)
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.tableRowIndex = null;
              this.getTableList();
            } else {
              this.$message.error(resp.message);
            }
          })
          .catch(() => {
            this.$message.error("删除失败");
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
._SelectHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._SelectHeader > div {
  display: flex;
  align-items: center;
}

/deep/.standrdDialog .el-form-item {
  margin-top: 10px;
  padding-right: 20px;
}

/deep/.standrdDialog .el-form-item__label {
  padding: 0 !important;
}
</style>
