import Vue from "vue";
import App from "./App.vue";
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store';
import pdf from './modules/PDF';
import { setRemPC, setRem } from "./modules/SetRem";
import { preventReClick } from './modules/directive'
import VueMoment from 'vue-moment';

Vue.use(ElementUI);
Vue.use(pdf);
Vue.config.productionTip = false;
Vue.use(preventReClick)
Vue.use(VueMoment);

new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    if (
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      //移动端使用
      setRem();
      window.addEventListener("resize", setRem); //浏览器窗口大小改变时调用rem换算方法
    } else {
      //pc端使用
      setRemPC();
      window.addEventListener("resize", setRemPC); //浏览器窗口大小改变时调用rem换算方法
    }
  },
}).$mount("#app");
