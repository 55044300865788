<template>
  <div>
    <Page :tabList="tabList"></Page>
  </div>
</template>

<script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabList: [
        { name: "区域管理", path: "/region-manage" },
        { name: "现场检查分类", path: "/category" },
        // { name: "体系分类", path: "/file-class-manage" },
        { name: "标准设置", path: "/standard-settings" },
        { name: "模块设置", path: "/module-settings" },
        { name: "过程职能表", path: "/process-fun-tab" },
        { name: "不符合项设置", path: "/no-compliant-items" },
        { name: "设置部门与题库", path: "/standar-with-dept" },
      ],
    };
  },
};
</script>

<style>
</style>