<template>
  <div class="container">
    <el-form :inline="true" class="form">
      <el-form-item label="审核方式:">
        <el-select v-model="AuditMethod" placeholder="请选择" clearable @change="changeMethod">
          <el-option
            v-for="item in methodList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年份:">
        <el-date-picker
          v-model="queryParams.Year"
          type="year"
          value-format="yyyy"
          placeholder="选择年"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="审核类型:">
        <el-select v-model="queryParams.AuditType" placeholder="请选择" clearable>
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" @click="handleSearch"></el-button>
      </el-form-item>
      <el-form-item style="position: absolute;right:0">
        <el-button class="el-icon-document-add" type="primary" @click="handleAdd">新增审核计划</el-button>
      </el-form-item>
    </el-form>
    <div>
      <!-- vda -->
      <el-table
        v-if="AuditMethod == 'VDA6.3审核'"
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        @selection-change="handleSelectionChange"
        @row-dblclick="dblclick"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="supplierName" label="供应商名称"></el-table-column>
        <el-table-column prop="auditDate" label="审核时间" width="150">
          <template slot-scope="scope">
            <span>{{renderTime(scope.row.auditDate)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="auditType" label="审核类型">
          <template slot-scope="scope">
            <div v-if="scope.row.auditType == 0">预审核</div>
            <div v-else>正式审核</div>
          </template>
        </el-table-column>
        <el-table-column prop="checkStatus" label="审核状态">
          <template slot-scope="scope">
            <div
              :style="scope.row.checkStatus == '2'?'color:#0055FF':''"
            >{{scope.row.checkStatus == '2'?'已审核':'未审核'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="preAuditResult" label="审核结果">
          <template slot-scope="scope">
            <div v-if="scope.row.preAuditResult == '黄'" class="ball">
              <span class="yellowBall"></span>
              <span>黄</span>
            </div>
            <div v-else-if="scope.row.preAuditResult == '绿'" class="ball">
              <span class="greenBall"></span>
              <span>绿</span>
            </div>
            <div v-else-if="scope.row.preAuditResult == '红'" class="ball">
              <span class="redBall"></span>
              <span>红</span>
            </div>
            <div v-else></div>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="产品名称"></el-table-column>
        <el-table-column prop="productId" label="产品编号">
          <template slot-scope="scope">{{ scope.row.productId }}</template>
        </el-table-column>
        <el-table-column prop="stage" label="阶段">
          <template slot-scope="scope">{{ scope.row.stage }}</template>
        </el-table-column>
        <el-table-column prop="productStatus" label="项目类型" width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.productStatus == '0'">过程设计</span>
            <span v-else-if="scope.row.productStatus == '1'">产品设计、过程设计</span>
          </template>
        </el-table-column>
        <el-table-column prop="auditReport" label="审核报告">
          <template slot-scope="scope">
            <el-button
              type="primary"
              plain
              @click="handleSee(scope.row)"
              style="border-radius: 15px !important;"
            >查看报告</el-button>
          </template>
        </el-table-column>
        <el-table-column label="措施关闭情况" width="200">
          <template slot-scope="scope">
            <div class="close">
              <span @click="handelClose(scope.row)">已关({{scope.row.closedCount}})</span>
              <span @click="handelClose(scope.row)">未关({{scope.row.notClosedCount}})</span>
              <img
                src="../../assets/images/linkBtn.png"
                style="cursor: pointer;"
                alt
                @click="handleLink(scope.row)"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 供应商 -->
      <el-table
        v-else
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        @selection-change="handleSelectionChange"
        @row-dblclick="dblclick"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="supplierName" label="供应商名称"></el-table-column>
        <el-table-column prop="auditType" label="审核类型">
          <template slot-scope="scope">
            <div v-if="scope.row.auditType == 0">供应商初审</div>
            <div v-else>年度审核</div>
          </template>
        </el-table-column>
        <el-table-column prop="checkStatus" label="审核状态">
          <template slot-scope="scope">
            <span v-if="scope.row.checkStatus == 0">未抽题</span>
            <span v-else-if="scope.row.checkStatus == 1">已抽题</span>
            <span v-else-if="scope.row.checkStatus == 2">已完成</span>
            <span v-else>未完成</span>
          </template>
        </el-table-column>
        <el-table-column prop="titleCount" label="抽取问题数"></el-table-column>
        <el-table-column prop="failCount" label="问题点数"></el-table-column>
        <el-table-column prop="passCount" label="不适用数"></el-table-column>
        <el-table-column prop="auditDate" label="审核时间" width="150">
          <template slot-scope="scope">
            <span>{{renderTime(scope.row.auditDate)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="completeTime" label="完成时间" width="150">
          <template slot-scope="scope">
            <span>{{renderTime(scope.row.completeTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saveTime" label="创建时间" width="150">
          <template slot-scope="scope">
            <span>{{renderTime(scope.row.saveTime)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
        class="_Pagination"
        v-show="count"
      ></el-pagination>
    </div>
    <!-- 新增年度审核计划弹框 -->
    <el-dialog title="新增审核计划" :visible.sync="dialogVisible" width="478px">
      <el-form
        :model="form"
        ref="form"
        label-width="auto"
        class="addForm"
        :rules="rules"
        :show-message="false"
      >
        <el-form-item label="审核方式" prop="auditMethod">
          <el-radio-group v-model="form.auditMethod" @change="changeMethod">
            <el-radio label="供应商质量体系审核"></el-radio>
            <el-radio label="VDA6.3审核"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核类型">
          <el-select
            v-model="form.auditType"
            placeholder="请选择"
            clearable
            style="width:100%"
            @change="changeType"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商">
          <el-select
            v-model="form.supplierId"
            placeholder="请选择"
            clearable
            style="width:100%"
            @change="change"
          >
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :value="item.id"
              :label="item.supplierName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核时间">
          <el-date-picker
            v-model="form.auditDate"
            type="datetime"
            placeholder="选择日期"
            style="width:100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="产品名称" v-if="form.auditMethod != '供应商质量体系审核'">
          <el-select
            v-model="form.productName"
            placeholder="请选择"
            clearable
            style="width:100%"
            @change="changeProduct"
          >
            <el-option
              v-for="item in productList"
              :key="item.id"
              :value="item.productName"
              :label="item.productName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品编号" v-if="form.auditMethod != '供应商质量体系审核'">
          <el-input v-model="form.productId" placeholder="请输入" disabled></el-input>
        </el-form-item>
        <el-form-item label="项目类型" v-if="form.auditMethod != '供应商质量体系审核'">
          <el-select v-model="form.productStatus" placeholder="请选择" style="width:100%" disabled>
            <el-option
              v-for="item in productTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="阶段" v-if="form.auditMethod != '供应商质量体系审核'">
          <el-select
            v-model="form.stage"
            placeholder="请选择"
            multiple
            clearable
            :disabled="form.auditType == 0?true:false"
            style="width:100%;height:40px"
          >
            <el-option
              v-for="item in stageList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 查看预审核弹框 -->
    <el-dialog title="查看审核记录" :visible.sync="dialogPredict" width="70%">
      <el-table
        :data="dataList"
        border
        stripe
        class="table formalTable"
        :cell-style="tableCell"
        :header-row-style="{color:'#000000'}"
      >
        <el-table-column label="审核结果"></el-table-column>
        <el-table-column label="红">{{preAuditForm.rCount}}</el-table-column>
        <el-table-column label="黄">{{preAuditForm.yCount}}</el-table-column>
        <el-table-column label="绿">{{preAuditForm.gCount}}</el-table-column>
        <el-table-column label="n.e.">{{preAuditForm.naCount}}</el-table-column>
        <el-table-column label="评级">
          <template>
            <div class="ball">
              <span :class="preAuditForm.level == 'r'?'redBall':'none'" style="margin-right:0"></span>
              <span :class="preAuditForm.level == 'y'?'yellowBall':'none'" style="margin-right:0"></span>
              <span :class="preAuditForm.level == 'g'?'greenBall':'none'" style="margin-right:0"></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="remarkBox">
        <div class="title">备注</div>
        <div class="content">
          <div class="ball">
            <span class="redBall"></span>
            <span>红</span>
          </div>
          <div class="supplier">
            <span>被禁止的供方：</span>
            <span>#NV</span>
          </div>
          <div class="nominate">
            不可能被提名：
            <span>#NV</span>
            <span>#NV</span>
            <span>#NV</span>
          </div>
        </div>
        <div class="content">
          <div class="ball">
            <span class="yellowBall"></span>
            <span>黄</span>
          </div>
          <div class="supplier">
            <span>被禁止的供方：</span>
            <span>#NV</span>
          </div>
          <div class="nominate">
            不可能被提名：
            <span>#NV</span>
            <span>#NV</span>
            <span>#NV</span>
          </div>
        </div>
        <div class="content">
          <div class="ball">
            <span class="greenBall"></span>
            <span>绿</span>
          </div>
          <div class="supplier">
            <span>被禁止的供方：</span>
            <span>#NV</span>
          </div>
          <div class="nominate">
            不可能被提名：
            <span>#NV</span>
            <span>#NV</span>
            <span>#NV</span>
          </div>
        </div>
        <p>此报告中确认的发现(包括符合和不符合的)将被用在原因分析,行动定义,行动实施和评价的有效性等方面。本报告将被用作为进一步分析的</p>
      </div>
      <table class="table formalTable">
        <tr>
          <td>发现/要求</td>
          <td></td>
        </tr>
        <tr>
          <td>过程描述：</td>
          <td>
            <el-input v-model="preAuditForm.processDesc"></el-input>
          </td>
        </tr>
        <tr>
          <td>风险评估：</td>
          <td>
            <el-input v-model="preAuditForm.riskAssess"></el-input>
          </td>
        </tr>
        <tr>
          <td>潜力评估：</td>
          <td>
            <el-input v-model="preAuditForm.potentialAssess"></el-input>
          </td>
        </tr>
        <tr>
          <td>进一步行动：</td>
          <td>
            <el-input v-model="preAuditForm.furtherAction"></el-input>
          </td>
        </tr>
      </table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogPredict = false">取 消</el-button>
        <el-button type="primary" @click="handelSave">保 存</el-button>
        <el-button type="primary" @click="handleCommit">提交报告</el-button>
      </div>
    </el-dialog>
    <!-- 查看正式审核弹框 -->
    <el-dialog title="查看审核记录" :visible.sync="dialogFormal" width="70%">
      <div class="satisfy">
        <div class="title">总体满足率</div>
        <div class="formula">
          <div class="eg">EG[%]=</div>
          <div class="divide">
            <div>389</div>
            <div class="line"></div>
            <div>430</div>
          </div>
          <div class="equal">=</div>
          <div class="result">
            <div>EG[%]</div>
            <div>定级</div>
            <div>{{FormalAuditForm.eg}}</div>
            <div>{{FormalAuditForm.level}}</div>
          </div>
        </div>
      </div>
      <el-table
        :data="formalData"
        border
        stripe
        class="table formalTable"
        :span-method="arraySpanMethod"
        :cell-style="tableBackground"
        :header-row-style="{color:'#000000'}"
        :header-cell-style="handerMethod"
      >
        <el-table-column prop="factor" label="评价要素" width="200"></el-table-column>
        <el-table-column prop="accord" label="符合率"></el-table-column>
        <el-table-column prop="coincidence" label="符合率"></el-table-column>
        <el-table-column label="0">
          <template slot-scope="scope">
            <el-progress :percentage="100" color="#C0C0C0" :show-text="false" :stroke-width="12"></el-progress>
            <el-progress
              :percentage="Number(scope.row.coincidence)"
              color="#4472C4"
              :show-text="false"
              :stroke-width="12"
            ></el-progress>
          </template>
        </el-table-column>
        <el-table-column label="20"></el-table-column>
        <el-table-column label="40"></el-table-column>
        <el-table-column label="60"></el-table-column>
        <el-table-column label="80"></el-table-column>
        <el-table-column label="100"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 措施关闭情况弹框 -->
    <el-dialog title="措施关闭情况" :visible.sync="dialogMeasure" width="70%">
      <img
        src="../../assets/images/linkBtn.png"
        alt
        style="position: absolute;top: 18px;left: 140px;"
      />
      <el-table
        :data="measureData"
        border
        stripe
        class="table"
        :cell-style="tableBackground"
        :header-row-style="{color:'#000000'}"
      >
        <el-table-column prop="serial" label="措施编号"></el-table-column>
        <el-table-column prop="auditResult" label="审核结果"></el-table-column>
        <el-table-column prop="score" label="得分"></el-table-column>
        <el-table-column prop="reson" label="原因">
          <template slot-scope="scope">
            <el-input v-model="scope.row.reson"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="measure" label="措施">
          <template slot-scope="scope">
            <el-input v-model="scope.row.measure"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="deadline" label="时限">
          <template slot-scope="scope">
            <el-input v-model="scope.row.deadline"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="dutyUserName" label="责任人">
          <template slot-scope="scope">
            <el-input v-model="scope.row.dutyUserName"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="isEffect" label="有效性">
          <template slot-scope="scope">
            <el-select v-model="scope.row.isEffect" clearable>
              <el-option
                v-for="item in effectList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="primary"
              plain
              @click="handleSubmit(scope.row)"
              style="border-radius: 15px !important;"
            >提交措施</el-button>
            <el-button
              type="primary"
              plain
              @click="handleValid(scope.row)"
              style="border-radius: 15px !important;"
            >有效性验证</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http.js";
export default {
  data() {
    return {
      queryParams: {
        Year: null,
        AuditType: null,
      },
      AuditMethod: "供应商质量体系审核",
      methodList: [
        {
          label: "供应商质量体系审核",
          value: "供应商质量体系审核",
        },
        {
          label: "VDA6.3审核",
          value: "VDA6.3审核",
        },
      ],
      typeList: [
        {
          label: "供应商初审",
          value: 0,
        },
        {
          label: "年度审核",
          value: 1,
        },
      ],
      productTypeList: [
        {
          label: "过程设计",
          value: "0",
        },
        {
          label: "产品设计、过程设计",
          value: "1",
        },
      ],
      supplierList: [],
      productList: [],
      elementHeight: 0,
      tableData: [],
      // 分页
      start: 0,
      end: 10,
      currentPage: 1,
      pageSize: 10,
      count: 0,
      multipleSelection: [],
      dialogVisible: false,
      form: {},
      dialogPredict: false,
      dialogFormal: false,
      dialogMeasure: false,
      formalData: [
        {
          factor: "p2-项目管理",
          accord: "Ep2",
          coincidence: "",
        },
        {
          factor: "P3一产品和过程开发策划",
          accord: "Ep3",
          coincidence: "",
        },
        {
          factor: "P4一产品和过程开发实现",
          accord: "Ep4",
          coincidence: "",
        },
        {
          factor: "P5一供方管理",
          accord: "Ep5",
          coincidence: "",
        },
        {
          factor: "P6一过程分析/生产",
          accord: "Ep6",
          coincidence: "",
        },
        {
          factor: "P7一顾客关怀/顾客满意程度",
          accord: "Ep7",
          coincidence: "",
        },
      ],
      measureData: [
        {
          serial: "ep2",
          auditResult: "aaa",
          score: "aaa",
          adoptMeasure: "",
          cause: "",
          measure: "",
          timing: "",
          person: "",
          validity: "",
        },
      ],
      stageList: [
        {
          label: "p1",
          value: "p1",
        },
        {
          label: "p2",
          value: "p2",
        },
        {
          label: "p3",
          value: "p3",
        },
        {
          label: "p4",
          value: "p4",
        },
        {
          label: "p5",
          value: "p5",
        },
        {
          label: "p6",
          value: "p6",
        },
      ], //阶段列表
      dataList: [],
      preAuditForm: {},
      FormalAuditForm: {},
      GuId: "",
      effectList: [
        {
          label: "无效",
          value: "0",
        },
        {
          label: "有效",
          value: "1",
        },
      ],
      rules: {
        auditMethod: [
          { required: true, message: "请选择审核方式", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    this.getElementHeight();
    let aside = document.querySelector(".aside");
    aside.style.display = "block";
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 56 + 48);
      });
    },
    // 处理时间格式
    renderTime(date) {
      if (date) {
        var time = new Date(date).toJSON();
        return new Date(+new Date(time) + 8 * 3600 * 1000)
          .toISOString()
          .replace(/T/g, " ")
          .replace(/\.[\d]{3}Z/, "");
      }
    },
    // 双击表格跳转审核页面
    dblclick(row) {
      if (this.AuditMethod == "供应商质量体系审核") {
        this.$router.push({
          path: "/SupplierAudit",
          query: {
            PlanId: row.id,
            status: row.checkStatus,
          },
        });
      } else {
        this.$router.push({
          path: "/Audit",
          query: {
            auditType: row.auditType,
            planId: row.id,
            productStatus: row.productStatus,
          },
        });
      }
    },
    // 查询审核计划列表
    getList() {
      let url =
        this.AuditMethod == "供应商质量体系审核"
          ? "/api/SupplierCheckplan/GetList"
          : "/api/VdaCheckplan/GetList";
      get(url, this.queryParams).then((res) => {
        
        this.tableData = res.data;
        this.count = res.count;
      });
    },
    // 查询供应商信息
    getSupplierList() {
      get("/api/VdaSupplier/GetAll").then((res) => {
        this.supplierList = res.data;
      });
    },
    // 正式审核获取产品信息
    getProductInfo() {
      get("/api/VdaProduct/GetMyList").then((res) => {
        this.productList = res.data;
      });
    },
    // 查询按钮
    handleSearch() {
      this.getList();
    },
    // 重置表单
    reset() {
      this.form = {
        id: 0,
        auditMethod: "",
        auditType: null,
        auditDate: "",
        supplierId: null,
        supplierName: "",
        checkStatus: null,
        // completeTime: null,
        remark: "",
        tenantId: JSON.parse(sessionStorage.getItem("userInfo")).tenantId,
        saveUserId: JSON.parse(sessionStorage.getItem("userInfo")).id,
        saveUserNo: "",
        saveUserName: JSON.parse(sessionStorage.getItem("userInfo")).userName,
        saveTime: new Date().toISOString(),
        isDelete: 0,
      };
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
    },
    // 新增供应商
    handleAdd() {
      this.reset();
      this.dialogVisible = true;
      this.getSupplierList();
    },
    // 新增审核计划提交按钮
    submitForm() {
      let url =
        this.form.auditMethod == "供应商质量体系审核"
          ? "/api/SupplierCheckplan"
          : "/api/VdaCheckplan";
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.form.stage = this.form.stage.toString();
          // delete this.form.auditMethod;
          post(url, this.form)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "新增成功",
                });
                this.dialogVisible = false;
                this.getList();
              } else {
                this.$message.error(res.message);
                this.form.stage = [];
              }
            })
            .then(() => {
              if (this.form.auditMethod == "供应商质量体系审核") {
                get("/api/SupplierCheckplan/GetList")
                  .then((res) => {
                    this.tableData = res.data;
                  })
                  .then(() => {
                    post(
                      "/api/SupplierCheckplan/InsertExtract?PlanId=" +
                        this.tableData[0].id
                    ).then((res) => {
                      if (res.code == 200) {
                        this.dblclick(this.tableData[0]);
                        this.getList();
                      } else {
                        this.$message.error(res.message);
                      }
                    });
                  });
              }
            });
        }
      });
    },
    // 审核方法change事件
    changeMethod(e) {
      if (e == "供应商质量体系审核") {
        this.typeList = [
          {
            label: "供应商初审",
            value: 0,
          },
          {
            label: "年度审核",
            value: 1,
          },
        ];
      } else {
        this.typeList = [
          {
            label: "预审核",
            value: 0,
          },
          {
            label: "正式审核",
            value: 1,
          },
        ];
      }
      this.getList();
    },
    // 审核类型change事件
    changeType(e) {
      this.form.auditType = e;
      if (this.form.auditMethod == "VDA6.3审核") {
        if (e == 1) {
          this.getProductInfo();
        } else {
          this.form.stage = ["p1"];
          this.productList = [];
        }
      }
    },
    // 供应商change事件
    change(e) {
      this.supplierList.forEach((item) => {
        if (item.id == e) {
          this.form.supplierName = item.supplierName;
        }
      });
      get("/api/VdaProduct/GetList?SupplierId=" + e).then((res) => {
        this.productList = res.data;
      });
    },
    // 获取产品信息
    changeProduct(e) {
      this.productList.forEach((item) => {
        if (e == item.productName) {
          this.form.productId = item.id;
          this.form.productStatus = item.productType;
        }
      });
    },
    // 查看审核记录
    handleSee(row) {
      if (row.auditType == 0) {
        this.dialogPredict = true;
        this.dataList = [];
        this.preAuditForm = {};
        get("/api/VdaAuditSubReport?PlanId=" + row.id).then((res) => {
          if (res.code == 200) {
            this.preAuditForm = res.data;
            this.dataList.push(res.data);
          }
        });
      } else {
        this.dialogFormal = true;
        this.FormalAuditForm = {};
        get("/api/VdaAuditReport?PlanId=" + row.id).then((res) => {
          if (res.code == 200) {
            this.FormalAuditForm = res.data;
            const { p2, p3, p4, p5, p6, p7 } = res.data;
            this.formalData.forEach((item, index) => {
              item.coincidence = [p2, p3, p4, p5, p6, p7][index];
            });
          }
        });
        // get("/api/VdaAuditReport/GetEuList?PlanId=" + row.id).then((res) => {
        //   console.log(res);
        // });
      }
    },
    // 预审核报告保存按钮
    handelSave() {
      put("/api/VdaAuditSubReport", this.preAuditForm).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.dialogPredict = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 预审核报告提交按钮
    handleCommit() {
      put("/api/VdaAuditSubReport/SubmitReport", this.preAuditForm).then(
        (res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "提交成功",
            });
            this.dialogPredict = false;
          } else {
            this.$message.error(res.message);
          }
        }
      );
    },
    // 措施情况关闭跳转链接
    handleLink(row) {
      this.$router.push({
        path: "/MeasureClosure",
        query: {
          auditType: row.auditType,
          planId: row.id,
        },
      });
    },
    tableBackground(cell) {
      document.getElementsByClassName(cell.column.id)[0].style.background =
        "#E8EBF4";
    },
    tableCell(cell) {
      if (cell.columnIndex == 1) {
        document.getElementsByClassName(cell.column.id)[0].style.background =
          "#C22828";
      } else if (cell.columnIndex == 2) {
        document.getElementsByClassName(cell.column.id)[0].style.background =
          "#DFDF11";
      } else if (cell.columnIndex == 3) {
        document.getElementsByClassName(cell.column.id)[0].style.background =
          "#74C91A";
      } else {
        document.getElementsByClassName(cell.column.id)[0].style.background =
          "#E8EBF4";
      }
    },
    //合并单位表头
    handerMethod({ row, columnIndex }) {
      if (row[0].level == 1) {
        //这里有个非常坑的bug 必须是row[0]=0 row[1]=2才会生效
        row[1].colSpan = 0;
        row[2].colSpan = 2;
        if (columnIndex === 1) {
          return { display: "none" };
        }
      }
    },
    // 合并行
    arraySpanMethod({ columnIndex }) {
      if (columnIndex > 2) {
        return [1, 6];
      }
    },
    // 删除按钮
    handleDelete(row) {
      let url =
        this.AuditMethod == "供应商质量体系审核"
          ? "/api/SupplierCheckplan"
          : "/api/VdaCheckplan";
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod(url + "?id=" + row.id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 措施关闭
    handelClose(row) {
      this.dialogMeasure = true;
      get("/api/VdaCheckplan/GetLink?PlanId=" + row.id).then((res) => {
        this.GuId = res.data;
        let data = {
          PlanId: row.id,
          GuId: this.GuId,
        };
        get("/api/VdaChecklisttrackLink", data).then((res) => {
          this.measureData = res.data;
        });
      });
    },
    // 措施提交按钮
    handleSubmit(row) {
      row.GuId = this.GuId;
      put("/api/VdaChecklisttrackLink/UpdateTwo", row).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 措施有效性验证按钮
    handleValid(row) {
      let data = {
        id: row.id,
        isEffect: row.isEffect,
        guId: this.GuId,
      };
      put("/api/VdaChecklisttrackLink/UpdateThree", data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "验证成功!",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.start = (this.currentPage - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
  },
};
</script>

<style lang="less" scoped>
table {
  border-collapse: collapse;
  width: 100%;
  tr:nth-child(1) {
    background: #e8ebf4;
    height: 46px;
  }
  tr {
    height: 44px;
    border: 1px solid #dcdcdc;
    td {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      border: 1px solid #dcdcdc;
    }
    td:nth-child(1) {
      width: 142px;
      text-align: center;
    }
    td:nth-child(2) {
      padding: 0 10px;
    }
  }
}
.form {
  .el-form-item {
    margin-bottom: 16px !important;
  }
  ::v-deep .el-form-item__label {
    font-weight: bold;
    color: #3d3d3d;
    padding-right: 6px;
  }
}
._Pagination {
  text-align: end;
  padding: 10px 20px;
  background-color: #ffffff;
}
.addForm {
  .el-form-item {
    margin-bottom: 10px !important;
    margin-right: 24px;
  }
  ::v-deep .el-input__inner {
    height: 40px !important;
  }
}
.table {
  ::v-deep .el-table__row td {
    border-bottom: 1px solid #e8e9ebdb !important;
  }
}
.ball {
  span {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .yellowBall {
    vertical-align: middle;
    background: #f6ff00;
    border: 1px solid #b8b80f;
  }
  .greenBall {
    vertical-align: middle;
    background: #00b050;
    border: 1px solid #0f6b39;
  }
  .redBall {
    vertical-align: middle;
    background: #ff0000;
    border: 1px solid #ff0000;
  }
  .none {
    width: 0;
    height: 0;
  }
}
.close {
  span {
    color: #0055ff;
  }
  :nth-child(1) {
    margin-right: 5px;
  }
  img {
    vertical-align: middle;
    margin-left: 20px;
  }
}
.remarkBox {
  margin-top: 18px;
  margin-bottom: 18px;
  .title {
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 12px;
  }
  .content {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    margin-bottom: 10px;
    .ball {
      display: flex;
      align-items: center;
      & > span:nth-child(2) {
        font-weight: bold;
        margin-left: 5px;
      }
    }
    .supplier {
      margin-left: 36px;
    }
    .nominate {
      margin-left: 36px;
      span {
        margin-right: 10px;
      }
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
}
.satisfy {
  width: 485px;
  height: 185px;
  background: #ffffff;
  border: 2px solid #000000;
  font-weight: bold;
  color: #000000;
  font-size: 18px;
  margin-bottom: 10px;
  .title {
    line-height: 18px;
    margin-top: 40px;
    margin-left: 56px;
  }
  .formula {
    display: flex;
    .eg {
      margin-top: 54px;
      margin-left: 77px;
      margin-right: 19px;
    }
    .divide {
      margin-top: 40px;
      .line {
        width: 40px;
        height: 2px;
        background: #000000;
        border: 1px solid #000000;
      }
    }
    .equal {
      margin-top: 54px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .result {
      width: 202px;
      height: 94px;
      background: #ccffcc;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      div {
        width: 50%;
      }
    }
  }
}
.formalTable {
  border: 2px solid #000000;
}

.productForm {
  .el-form-item {
    margin-bottom: 10px !important;
    margin-right: 2px;
  }
  ::v-deep .el-input__inner {
    height: 40px !important;
  }
  ::v-deep .el-input {
    width: calc(100% - 22px);
  }
  ::v-deep .el-icon-close {
    margin-left: 8px;
  }
}
::v-deep .el-progress-bar__inner {
  border-radius: 0;
  border: 1px solid #000000;
}
::v-deep .el-progress-bar__outer {
  background: none;
  border-radius: 0;
}
</style>