<template>
  <div>
    <Page :tabList="tabList"></Page>
  </div>
</template>

<script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabIndex: 0,
      tabList: [
        {
          name: "常规标准审核",
          path: "/plan-page",
        },
        {
          name: "过程审核(6.3)",
          path: "/SelfAnnualAuditPlan",
          // children: [
          //   { name: "供应商名单", path: "/Supplier" },
          //   { name: "年度审核计划", path: "/AnnualAuditPlan" },
          //   { name: "问题综合表", path: "/ProblemSynthesis" },
          //   { name: "资料综合表", path: "/DataSynthesis" },
          //   { name: "供应商审核题库", path: "/SupplierQuestion" },
          //   { name: "产品清单", path: "/SelfInfoRegister" },
          //   { name: "审核计划", path: "/SelfAnnualAuditPlan" },
          // ],
          children: [
            { name: "产品清单", path: "/SelfInfoRegister" },
            { name: "审核计划", path: "/SelfAnnualAuditPlan" },
          ],
        },

        // {
        //   name: "供应商审核",
        //   path: "./Supplier",
        // },
        {
          name: "产品审核(6.5)",
          path: "",
        },
        // {
        //   name: "体系落地检查",
        //   path: "/plan-page",
        // },
        // {
        //   name: "现场管理检查",
        //   path: "/site-page",
        // },
        // {
        //   name: "考核细则",
        //   path: "/rules-page",
        // },
        // {
        //   name: "内外审检查",
        //   path: "/QuestionPoint",
        // },
        // {
        //   name: "控制中心",
        //   path: "/control-page",
        // },
        // {
        //   name: "学习中心",
        //   path: "/learn-page",
        //   children:[
        //       {
        //         name:"文件学习",
        //         path: '/file-learn',
        //       },
        //       {
        //         name:"专题学习",
        //         path: '/special-learn',
        //       },
        //       {
        //         name:"正式业务测评",
        //         path: '/formal',
        //       },
        //   ]
        // },
        // {
        //   name: "不符合跟踪",
        //   path: "/follow-examine",
        // },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped >
</style>